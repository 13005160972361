import { supabase } from "../utils/supabase";

//ファイルアップロード
export async function uploadFile(
    bucketName: string,
    filePath: string,
    file: File
): Promise<{ Key: string }> {
    const { data, error } = await supabase.storage
        .from(bucketName)
        .upload(filePath, file);
    if (error) {
        console.error('Upload error', error.message);
        throw new Error(error.message);
    }
    if (!data) {
        console.error('Upload failed: No data returned');
        throw new Error('Upload failed: No data returned');
    }
    return { Key: data.path };
}

//ファイル取得
export const getFileUrl = async (bucketName: string, path: string): Promise<string> => {
    const { data } = supabase
        .storage
        .from(bucketName)
        .getPublicUrl(path);
    return data.publicUrl || '';
};

// ファイル削除関数
export const removeFile = async (bucketName: string, filePath: string) => {
    const { error } = await supabase.storage.from(bucketName).remove([filePath]);
    if (error) {
        throw error;
    }
};

//ダウンロードのために公開URLを取得
export const getFilePublicUrl = async (bucketName: string, filePath: string) => {
    try {
        const response = supabase.storage.from(bucketName).getPublicUrl(filePath);
        const publicUrl = response.data?.publicUrl;
        if (!publicUrl) {
            console.error('Error getting file public URL: URL is not available');
            return '';
        }
        return publicUrl;
    } catch (error) {
        console.error('Error getting file public URL:', error);
        return '';
    }
};
