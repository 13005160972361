import {
  Box,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Chip,
  IconButton,
  InputAdornment,
  Paper,
  TablePagination,
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import PageContainer from "../components/pageContainer";
import { useNavigate } from "react-router-dom";
import { getAllKomutenCustomer } from "../service/customerService";
import { green, red } from '@mui/material/colors';
import { useRecoilValue } from 'recoil';
import { komutenState } from "../recoil/komutenState";
import SearchIcon from '@mui/icons-material/Search';

const CustomerList: FC = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [customerList, setCustomerList] = useState<any[]>([]);
  const komuten = useRecoilValue(komutenState);
  const [searchQuery, setSearchQuery] = useState('');
  const [tempSearchQuery, setTempSearchQuery] = useState('');

  const [sortBy, setSortBy] = useState<string>('created_at');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');

  const handleSort = (column: string) => {
    if (sortBy === column) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(column);
      setSortOrder('asc');
    }
  };

  useEffect(() => {
    const komouten_uid = komuten ? komuten.komuten_uid : "";
    const fetchCustomerList = async () => {
      const { data, totalCount, error } = await getAllKomutenCustomer(komouten_uid, page + 1, rowsPerPage, searchQuery, sortBy, sortOrder);

      if (error) {
        console.error("Error:", error);
        alert('データの取得時にエラーが発生しました。もう一度お試しください。');
        return;
      }

      setCustomerList(data);
      setTotalRecords(totalCount || 0);
    };

    fetchCustomerList();
  }, [page, komuten, searchQuery, sortBy, sortOrder, rowsPerPage]);

  const executeSearch = () => {
    setSearchQuery(tempSearchQuery);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTempSearchQuery(event.target.value);
  };

  const handlePageChange = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRowClick = (customer_id: number) => {
    navigate(`/customer/${customer_id}`);
  };

  return (
    <PageContainer>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignContent: "center",
          marginBottom: 3
        }}
      >
        <Typography
          variant="h4"
          component="div"
        >
          オーナー管理
        </Typography>
        <Box
          component="form"
          sx={{ display: 'flex', alignItems: 'center', width: '30%' }}
          onSubmit={(event) => {
            event.preventDefault();
            executeSearch();
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            label="検索"
            variant="outlined"
            size="small"
            fullWidth
            value={tempSearchQuery}
            onChange={handleSearchChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="search"
                    onClick={executeSearch}
                    edge="end"
                  >
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
        {/* <Box
          sx={{ display: 'flex', alignItems: 'center', width: '30%' }}
        >
          {/* 検索フォーム */}
        {/* <Box
            component="form"
            sx={{ display: 'flex', alignItems: 'center', maxWidth: '600px', width: '100%' }}
            onSubmit={(event) => {
              event.preventDefault(); // フォームのデフォルトの送信を防ぐ
              executeSearch();
            }}
            noValidate
            autoComplete="off"
          >
            <TextField
              label="検索"
              variant="outlined"
              size="small"
              fullWidth
              value={tempSearchQuery}
              onChange={handleSearchChange}
            />
          </Box> */}
        {/* <Button
            variant="contained"
            onClick={executeSearch}
            sx={{ height: '40px', ml: 1 }}
          >
            検索
          </Button> */}
        {/* </Box> */}
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell onClick={() => handleSort('name')}>
                氏名
                {sortBy === 'name' ? (sortOrder === 'asc' ? '△' : '▽') : ''}
              </TableCell>
              <TableCell onClick={() => handleSort('zyusyo')}>
                住所 {sortBy === 'zyusyo' && (sortOrder === 'asc' ? '△' : '▽')}
              </TableCell>
              <TableCell onClick={() => handleSort('ruiseki_point')}>
                ポイント {sortBy === 'ruiseki_point' && (sortOrder === 'asc' ? '△' : '▽')}
              </TableCell>
              <TableCell onClick={() => handleSort('kubun')}>
                区分 {sortBy === 'kubun' && (sortOrder === 'asc' ? '△' : '▽')}
              </TableCell>
              <TableCell onClick={() => handleSort('tantosya')}>
                担当者 {sortBy === 'tantosya' && (sortOrder === 'asc' ? '△' : '▽')}
              </TableCell>
              <TableCell onClick={() => handleSort('setting_state')}>
                設定状況 {sortBy === 'setting_state' && (sortOrder === 'asc' ? '△' : '▽')}
              </TableCell>
              <TableCell onClick={() => handleSort('updated_at')}>
                更新日 {sortBy === 'updated_at' && (sortOrder === 'asc' ? '△' : '▽')}
              </TableCell>
              <TableCell onClick={() => handleSort('created_at')}>
                登録日 {sortBy === 'created_at' && (sortOrder === 'asc' ? '△' : '▽')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {customerList.length > 0 ? (
              customerList.map((row, index) => (
                <TableRow
                  key={index}
                  onClick={() => handleRowClick(row.id)}
                  sx={{
                    cursor: 'pointer',
                    backgroundColor: index % 2 === 0 ? 'white' : 'rgba(240, 240, 240, 0.8)',
                  }}
                >
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{row.zyusyo}</TableCell>
                  <TableCell>{row.ruiseki_point}</TableCell>
                  <TableCell>{row.kubun}</TableCell>
                  <TableCell>{row.tantosya}</TableCell>
                  <TableCell>
                    {row.setting_state ? (
                      <Chip label="設定済み" variant="outlined" style={{ borderColor: green[500], color: green[500], backgroundColor: 'white' }} />
                    ) : (
                      <Chip label="未設定" variant="outlined" style={{ borderColor: red[500], color: red[500], backgroundColor: 'white' }} />
                    )}
                  </TableCell>
                  <TableCell>{new Date(row.updated_at).toLocaleDateString()}</TableCell>
                  <TableCell>{new Date(row.created_at).toLocaleDateString()}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={8} align="center">オーナーデータがありません。</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <Box display="flex" justifyContent="space-between" alignItems="center" p={1}>
          <Typography variant="body2" color="textSecondary">
            件数：{totalRecords}件
          </Typography>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={totalRecords}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
            labelRowsPerPage="表示件数:"
            labelDisplayedRows={({ from, to }) => `${from} - ${to}`}
          />
        </Box>
      </TableContainer>
    </PageContainer>
  );
};
export default CustomerList;
