import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";

import {
  // Alert,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  // FormControl,
  IconButton,
  // InputLabel,
  Menu,
  MenuItem,
  Pagination,
  Paper,
  // Select,
  // SelectChangeEvent,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  // TextField,
  Typography,
} from "@mui/material";
import React, { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
// import { getDraftList, getExport } from "../api/draft";
import PageContainer from "../../components/pageContainer";
import { PATHS } from "../../paths";
// import { PRRecord } from "../types/pr";
import { InputMode } from "../../types/inputMode";
import { deleteCompany, getCompanyList } from "../../api/sakuzyoyotei/company";
import { CompanyPage, CompanyRecord } from "../../types/sakuzyoyotei/company";

export type AccountMenuProps = {
  id: string;
  onCompleted: () => void;
};

const AccountMenu: FC<AccountMenuProps> = (props) => {
  // routerの定義
  // const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const handleDeleteClick = () => {
    setDeleteDialogOpen(true);
  };

  const handleDeleteConfirm = () => {
    deleteCompany(props.id).then((res) => {
      if (res.status === 200) {
        props.onCompleted();
      }

      setAnchorEl(null);
      setDeleteDialogOpen(false);
    });
  };

  return (
    <div>
      <IconButton
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <MoreVertOutlinedIcon></MoreVertOutlinedIcon>
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => {
          setAnchorEl(null);
        }}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {/* <MenuItem
          onClick={() => {
            navigate(`${PATHS.ACCOUNT}/${companyID}?mode=${InputMode.UPDATE}`);
          }}
        >
          編集
        </MenuItem> */}
        <MenuItem onClick={handleDeleteClick}>
          <Typography color="error">削除</Typography>
        </MenuItem>
      </Menu>
      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        fullWidth
      >
        <DialogTitle>削除確認</DialogTitle>
        <DialogContent>
          <DialogContentText>このアカウントを削除しますか？</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>キャンセル</Button>
          <Button onClick={handleDeleteConfirm} color="error">
            削除
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export type AccountListProps = {};

const AccountList: FC<AccountListProps> = (props) => {
  const navigate = useNavigate();

  const rowsPerPage = 10;
  const [page,] = useState(1);
  const [companyData, setCompanyData] = useState<CompanyRecord[]>();
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [openSnackbar, setOpenSnackbar] = useState(false); // Snackbar の開閉状態

  // データの再取得とSnackbar表示のための関数
  const refreshDataAndShowSnackbar = () => {
    setTableData();
    setOpenSnackbar(true); // Snackbar を表示
  };

  const handleEdit = () => {
    // navigate(PATHS.DRAFT_DETAIL);
  };

  // const handleCreate = () => {
  //   // navigate(PATHS.DRAFT_DETAIL);
  // };

  const setTableData = () => {
    getCompanyList(page).then((data: CompanyPage) => {
      setCompanyData(data.data);
      setTotalDataCount(data.company_total_count);
    });
  };

  // useEffect(() => {
  //   setTableData();
  // }, [page]); // ページ変更時にデータを再取得

  // const handlePageChange = (
  //   event: React.ChangeEvent<unknown>,
  //   newPage: number
  // ) => {
  //   setPage(newPage);
  // };

  const isDataAvailable = companyData && companyData.length > 0;
  const pageCount = Math.ceil(totalDataCount / rowsPerPage);

  return (
    <PageContainer>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignContent: "center",
        }}
      >
        <Typography
          variant="h4"
          component="div"
          sx={{ flexGrow: 1, textAlign: "left", marginBottom: 3 }}
        >
          アカウント管理
        </Typography>
        {/* <TextField
          label="検索"
          sx={{
            width: "425px",
          }}
        /> */}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <Button
          variant="contained"
          onClick={() =>
            navigate(`${PATHS.ACCOUNT}/create?mode=${InputMode.CREATE}`)
          }
        >
          アカウント作成
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>企業名</TableCell>
              <TableCell>メールアドレス</TableCell>
              <TableCell>代表者名</TableCell>
              <TableCell>権限</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {companyData &&
              companyData.map((row, index) => {
                return (
                  <TableRow hover key={index} onDoubleClick={handleEdit}>
                    <TableCell height={52}>{row.company_name}</TableCell>
                    <TableCell>{row.email}</TableCell>
                    <TableCell>{row.staff}</TableCell>
                    <TableCell>
                      <Chip
                        label={row.role === "admin" ? "管理者" : "一般"}
                        color={row.role === "admin" ? "primary" : "default"}
                      ></Chip>
                    </TableCell>
                    <TableCell align="right">
                      {row.email && (
                        <AccountMenu
                          id={row.company_id}
                          onCompleted={refreshDataAndShowSnackbar}
                        />
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        <Box
          position={"relative"}
          alignItems={"center"}
          justifyContent={"center"}
          display={"flex"}
          padding={2}
          sx={{ width: "100%" }}
        >
          <span style={{ position: "absolute", left: "16px" }}>
            {isDataAvailable
              ? `${Math.min(
                (page - 1) * rowsPerPage + 1,
                companyData.length
              )}から${Math.min(page * rowsPerPage, companyData.length)}件表示`
              : "データがありません"}
          </span>
          <Pagination
            count={pageCount}
            page={page}
            // onChange={handlePageChange}
            shape="rounded"
            color="primary"
            showFirstButton
            showLastButton
          />
        </Box>
      </TableContainer>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        message="アカウントが正常に削除されました"
      />
    </PageContainer>
  );
};
export default AccountList;
