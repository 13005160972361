import { supabase } from "../utils/supabase";

export const insertBannerMetadata = async (komuten_id: number, file_path: string, banner_number: number, image_name: string, hp_url: string) => {
  const { data, error } = await supabase
    .rpc('insert_banner_metadata', {
      _komuten_id: komuten_id,
      _file_path: file_path,
      _banner_number: banner_number,
      _image_name: image_name,
      _hp_url: hp_url
    });

  if (error) {
    console.error('Error inserting banner metadata:', error);
    throw new Error(error.message);
  }

  return data;
};


export const fetchBanner = async (komutenId: number) => {
  try {
    const { data, error } = await supabase
      .rpc('fetch_banner', { _komuten_id: komutenId });

    if (error) {
      console.error('Error fetching banner:', error);
      return []; // エラーがあった場合は空の配列を返す
    }
    return data || []; // dataがundefinedの場合は空の配列を返す
  } catch (error) {
    console.error('Fetch baner error:', error);
    return []; // 例外が発生した場合も空の配列を返す
  }
};


// ファイルメタデータを削除する関数
export const deleteBannerMetadata = async (id: number) => {
  const { data, error } = await supabase
    .rpc('delete_banner_by_id', {
      _id: id
    });

  if (error) {
    throw new Error(error.message);
  }

  return data;
};

// バナーメタデータを更新する関数
export const updateBannerMetadata = async (komuten_id: number, banner_number: number, new_hp_url: string) => {
  console.log('updateBannerMetadata呼び出し', komuten_id, banner_number, new_hp_url);
  const { data, error } = await supabase
    .rpc('update_banner_metadata', {
      _komuten_id: komuten_id,
      _banner_number: banner_number,
      _new_hp_url: new_hp_url
    });

  if (error) throw new Error(error.message);

  return data;
};