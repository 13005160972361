import React, { useEffect, ReactNode } from 'react';
import { useSetRecoilState } from 'recoil';
import { ThemeProvider } from "@emotion/react";
import { createTheme } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useNavigate
} from "react-router-dom";
import "./App.css";
import AuthSignIn from "./pages/authSignIn";
import AuthPasswordForGet from "./pages/sakuzyoyotei/authPasswordForget";
import DraftDetail from "./pages/sakuzyoyotei/draftDetail";
import Setting from "./pages/setting";
import CustomerList from "./pages/customerList";
import DraftList from "./pages/sakuzyoyotei/draftList";
import { PATHS } from "./paths";
import AuthAccount from "./pages/sakuzyoyotei/authAccount";
import AccountList from "./pages/sakuzyoyotei/accountList";
import AccountDetail from "./pages/sakuzyoyotei/accountDetail";
import CustomerDetail from "./pages/customerDetail";
import PromptDetail from "./pages/sakuzyoyotei/promptDetail";
import AuthPasswordReset from "./pages/authPasswordReset";
import AuthSignUp from "./pages/authSignUp";
import { komutenState } from './recoil/komutenState';
import { useRecoilValue } from 'recoil';
import NotFoundPage from './pages/notFoundPage';
import { authState } from './recoil/authState';

function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Navigate replace to={PATHS.AUTH_SIGNIN} />} />
      <Route path={PATHS.AUTH_SIGNIN} element={<AuthSignIn />} />
      <Route path={PATHS.AUTH_SIGNUP} element={<AuthSignUp />} />
      <Route path={PATHS.AUTH_PASSWORD_RESET} element={<AuthPasswordReset />} />
      <Route path={PATHS.AUTH_PASSWORD_FORGET} element={<AuthPasswordForGet />} />
      <Route path={PATHS.AUTH_ACCOUNT} element={<AuthAccount />} />
      <Route path="*" element={<NotFoundPage />} />
      {/* 保護が必要なルート */}
      <Route path={PATHS.CUSTOMER} element={
        <ProtectedRoute>
          <CustomerList />
        </ProtectedRoute>
      } />
      <Route path={`${PATHS.CUSTOMER}/:customer_id`} element={
        <ProtectedRoute>
          <CustomerDetail />
        </ProtectedRoute>
      } />
      <Route path={PATHS.SETTING} element={
        <ProtectedRoute>
          <Setting />
        </ProtectedRoute>
      } />
      <Route path={PATHS.DRAFT} element={
        <ProtectedRoute>
          <DraftList />
        </ProtectedRoute>
      } />
      <Route path={`${PATHS.DRAFT}/:id`} element={
        <ProtectedRoute>
          <DraftDetail />
        </ProtectedRoute>
      } />
      <Route path={PATHS.PRONPT} element={
        <ProtectedRoute>
          <PromptDetail />
        </ProtectedRoute>
      } />
      <Route path={PATHS.ACCOUNT} element={
        <ProtectedRoute>
          <AccountList />
        </ProtectedRoute>
      } />
      <Route path={`${PATHS.ACCOUNT}/:id`} element={
        <ProtectedRoute>
          <AccountDetail />
        </ProtectedRoute>
      } />
    </Routes>
  );
}

// 保護されたルートのコンポーネント
type ProtectedRouteProps = {
  children: ReactNode;
};

//未ログインの場合はログインページにリダイレクト
// const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
//   const komutenData = useRecoilValue(komutenState);
//   if (!komutenData) {
//     console.log('ProtectedRoute: not logged in');
//     return <Navigate to={PATHS.AUTH_SIGNIN} replace />;
//   }
//   return <>{children}</>;
// };

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const { isLoading, isAuthenticated } = useRecoilValue(authState);

  if (isLoading) {
    return <div>Loading...</div>; // 読み込み中の表示
  }

  if (!isAuthenticated) {
    console.log('ProtectedRoute: not logged in');
    return <Navigate to={PATHS.AUTH_SIGNIN} replace />;
  }

  return <>{children}</>;
};


const NavigationHandler = () => {
  const setUser = useSetRecoilState(komutenState);
  const navigate = useNavigate();

  const setAuth = useSetRecoilState(authState);

  useEffect(() => {
    const storedKomutenData = localStorage.getItem('komutenData');
    if (storedKomutenData) {
      const komutenData = JSON.parse(storedKomutenData);
      setUser(komutenData); // ユーザーデータをRecoil Stateにセット
      setAuth({ isLoading: false, isAuthenticated: true }); // 認証状態を更新
    } else {
      setAuth({ isLoading: false, isAuthenticated: false }); // 認証状態を更新
    }
  }, [setUser, setAuth]);

  useEffect(() => {
    const storedKomutenData = localStorage.getItem('komutenData');
    const currentPath = window.location.pathname;
    if (storedKomutenData) {
      const komutenData = JSON.parse(storedKomutenData);
      setUser(komutenData); // ユーザーデータをRecoil Stateにセット

      // PATHS.AUTH_SIGNINやPATHS.AUTH_SIGNUPとcurrentPathが一致するか直接確認
      if (currentPath === PATHS.AUTH_SIGNIN || currentPath === PATHS.AUTH_SIGNUP) {
        navigate(PATHS.CUSTOMER);
      }

    } else {
      // ユーザーデータがlocalStorageにない場合はログインページにリダイレクト
      // ただし、すでにログインページやサインアップページにいる場合はリダイレクトしない
      if (currentPath !== PATHS.AUTH_SIGNIN && currentPath !== PATHS.AUTH_SIGNUP) {
        navigate(PATHS.AUTH_SIGNIN);
      }
    }
  }, [setUser, navigate]);

  return null; // UI をレンダリングする必要がない場合は null を返す
};



function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#096BDE",
        contrastText: "#fff",
      },
      background: {
        default: "#FAFAFA",
      },
    },
    typography: {
      fontFamily: [
        "Roboto",
        '"Noto Sans JP"',
        '"Helvetica"',
        "Arial",
        "sans-serif",
      ].join(","),
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          dateFormats={{ monthAndYear: "YYYY年MM月" }}
          adapterLocale="ja"
        >
          <BrowserRouter>
            <NavigationHandler />
            <AppRoutes />
          </BrowserRouter>
        </LocalizationProvider>
      </div>
    </ThemeProvider>
  );
}

export default App;
