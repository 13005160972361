import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box, Button, Card, TextField, Typography, Alert, InputAdornment, IconButton
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import AuthContainer from '../components/authContainer';
// import { updatePassword } from '../api/auth';


const AuthPasswordReset: React.FC = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [isError, setError] = useState('');
  const [isSuccess, setSuccess] = useState(false);
  const navigate = useNavigate();


  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const handleConfirmPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(event.target.value);
  };


  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (password !== confirmPassword) {
      setError('パスワードが一致しません。');
      return;
    }
    if (password.length < 6) {
      setError('パスワードは6文字以上である必要があります。');
      return;
    }

    try {
      // await updatePassword(password);
      setSuccess(true);
      setTimeout(() => navigate('/signin'), 5000);
    } catch (error) {
      setError('パスワードのリセットに失敗しました。もう一度お試しください。');
      console.error('Password reset error:', error);
    }
  };


  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <AuthContainer>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
        <Card sx={{ padding: 3, minWidth: 300 }}>
          <Typography variant="h5" sx={{ mb: 2 }}>パスワード再設定</Typography>
          {isError && <Alert severity="error">{isError}</Alert>}
          {isSuccess && (
            <Alert severity="success">
              パスワードリセットに成功しました。ログインページ自動遷移します。
            </Alert>
          )}
          <form onSubmit={handleSubmit}>
            <TextField
              type={showPassword ? 'text' : 'password'}
              label="新しいパスワード"
              fullWidth
              margin="normal"
              value={password}
              onChange={handlePasswordChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={togglePasswordVisibility}
                    >
                      {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              type={showPassword ? 'text' : 'password'}
              label="パスワード確認"
              fullWidth
              margin="normal"
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={togglePasswordVisibility}
                    >
                      {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3 }}>パスワードを再設定</Button>
          </form>
        </Card>
      </Box>
    </AuthContainer>
  );
};

export default AuthPasswordReset;
