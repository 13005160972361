import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";

import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Pagination,
  Paper,
  Select,
  // SelectChangeEvent,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  // TextField,
  Typography,
} from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import { getDraftList, getExport } from "../api/draft";
import PageContainer from "../../components/pageContainer";
import { PATHS } from "../../paths";
import { PRRecord } from "../../types/sakuzyoyotei/pr";
import { InputMode } from "../../types/inputMode";
// import { deletePR, getPRList } from "../api/pr"; // Commented out
import { convertDateFormat } from "../../utils/datetimeUtils";
import { truncateText } from "../../utils/textUtils";

export type DraftMenuProps = {
  id: number;
  onDeleteComplete: () => void; // 削除完了時のコールバック関数
};

const DraftMenu: FC<DraftMenuProps> = (props) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const handleDeleteClick = () => {
    setDeleteDialogOpen(true);
    setAnchorEl(null);
  };

  const handleDeleteConfirm = () => {
    // deletePR(props.id).then(() => {
    //   setDeleteDialogOpen(false);
    //   props.onDeleteComplete();
    // });
  };

  return (
    <div>
      <IconButton
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={(event) => setAnchorEl(event.currentTarget)}
      >
        <MoreVertOutlinedIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() =>
            navigate(
              `${PATHS.DRAFT}/${InputMode.CREATE}?mode=${InputMode.COPY}&tab=first&copy_id=${props.id}`
            )
          }
        >
          コピーして作成
        </MenuItem>
        <MenuItem
          onClick={() =>
            navigate(
              `${PATHS.DRAFT}/${props.id}?mode=${InputMode.UPDATE}&tab=first`
            )
          }
        >
          編集
        </MenuItem>
        <MenuItem onClick={handleDeleteClick}>
          <Typography color="error">削除</Typography>
        </MenuItem>
      </Menu>
      {/* 削除ダイアログ */}
      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        fullWidth
      >
        <DialogTitle>削除確認</DialogTitle>
        <DialogContent>
          <DialogContentText>このPRを削除しますか？</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>キャンセル</Button>
          <Button onClick={() => handleDeleteConfirm()} color="error">
            削除
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export type DraftListProps = {};

const DraftList: FC<DraftListProps> = (props) => {
  const navigate = useNavigate();

  const rowsPerPage = 10;
  const [page, setPage] = useState(1);
  const [totalRecords,] = useState(0); // 総レコード数
  const [draftData,] = useState<PRRecord[]>();
  const [open, setOpen] = React.useState(false);
  const [category, setCategory] = React.useState<number>(0);
  const [categoryError, setCategoryError] = React.useState(false); // 新しいステート変数
  const [openSnackbar, setOpenSnackbar] = useState(false);

  useEffect(() => {
    // getPRList(page).then((data: PRPage) => { // Commented out
    //   setDraftData(data.data);
    //   setTotalRecords(data.pr_total_count);
    //   console.log(data.pr_total_count);
    // }); // Commented out
  }, [page]);

  // データがあるかどうかをチェック
  const isDataAvailable = totalRecords > 0;

  // ページネーション用のデータ
  const pageCount = isDataAvailable ? Math.ceil(totalRecords / rowsPerPage) : 0;

  // // 現在のページのデータを取得する関数
  // const getCurrentPageData = () => {
  //   const start = (page - 1) * rowsPerPage;
  //   let pageData = draftData.slice(start, start + rowsPerPage);

  //   // データが10件未満の場合、空の行を追加する
  //   while (pageData.length < rowsPerPage) {
  //     pageData.push({} as PRRecord);
  //   }

  //   return pageData;
  // };

  return (
    <PageContainer>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignContent: "center",
        }}
      >
        <Typography
          variant="h4"
          component="div"
          sx={{ flexGrow: 1, textAlign: "left", marginBottom: 3 }}
        >
          ドラフト管理
        </Typography>
        {/* <TextField
          label="検索"
          sx={{
            width: "425px",
          }}
        /> */}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        {/* <Typography marginRight={2} color={"#00000099"}>
          当月プロンプト使用量 ( 150 / 200 )
        </Typography> */}
        <Button variant="contained" onClick={() => setOpen(true)}>
          ドラフト作成
        </Button>
        <Dialog open={open} fullWidth>
          <DialogTitle>新規作成</DialogTitle>
          <DialogContent>
            <Box paddingTop={1}></Box>
            <FormControl fullWidth error={categoryError}>
              <InputLabel>プレリリースカテゴリ</InputLabel>
              <Select
                value={category}
                label="プレリリースカテゴリ"
                onChange={(event) => {
                  setCategory(event.target.value as number);
                  setCategoryError(event.target.value === null); // カテゴリがnullの場合、エラーを設定
                }}
              >
                <MenuItem value={1}>商品サービス</MenuItem>
                <MenuItem value={2}>イベント</MenuItem>
                <MenuItem value={3}>キャンペーン</MenuItem>
                <MenuItem value={4}>調査</MenuItem>
                <MenuItem value={5}>IR（業務提携）</MenuItem>
                <MenuItem value={6}>IR（人事）</MenuItem>
              </Select>
              {categoryError && (
                <FormHelperText>カテゴリを選択してください</FormHelperText>
              )}
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)}>キャンセル</Button>

            <Button
              onClick={() => {
                if (category === null) {
                  setCategoryError(true); // カテゴリがnullの場合、エラーを設定
                  return;
                }

                // 新規作成用のメソッドを記載する
                navigate(
                  `${PATHS.DRAFT}/create?mode=${InputMode.CREATE}&category=${category}`
                );
              }}
              variant="contained"
            >
              作成
            </Button>
          </DialogActions>
        </Dialog>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ whiteSpace: "nowrap" }}>作成日時</TableCell>
              <TableCell sx={{ whiteSpace: "nowrap" }}>ドラフトID</TableCell>
              <TableCell sx={{ whiteSpace: "nowrap" }}>タイトル</TableCell>
              <TableCell sx={{ whiteSpace: "nowrap" }}>ステータス</TableCell>
              <TableCell sx={{ whiteSpace: "nowrap" }}>担当者</TableCell>
              <TableCell sx={{ whiteSpace: "nowrap" }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {draftData &&
              draftData.map((row, index) => {
                return (
                  <TableRow
                    hover
                    key={row.pr_id || `empty-${index}`}
                  // onClick={
                  //   row.pr_id
                  //     ? () =>
                  //         navigate(
                  //           `${PATHS.DRAFT}/${row.pr_id}?mode=${InputMode.UPDATE}&tab=first`
                  //         )
                  //     : undefined
                  // }
                  >
                    <TableCell height={52} sx={{ whiteSpace: "nowrap" }}>
                      {!row.pr_id ? "" : convertDateFormat(row.create_datetime)}
                    </TableCell>
                    <TableCell>{row.pr_id}</TableCell>
                    <TableCell>
                      {truncateText(row.pr_title || "", 40)}
                    </TableCell>
                    <TableCell>
                      {row.pr_id ? (
                        <Chip
                          variant={
                            row.status === "release" ? "filled" : "outlined"
                          }
                          label={
                            row.status === "release" ? "作成完了" : "下書き"
                          }
                          color={
                            row.status === "release" ? "primary" : "default"
                          }
                        />
                      ) : (
                        ""
                      )}
                    </TableCell>
                    <TableCell sx={{ whiteSpace: "nowrap" }}>
                      {row.staff_name}
                    </TableCell>
                    <TableCell align="right">
                      {row.pr_id ? (
                        <DraftMenu
                          id={row.pr_id}
                          onDeleteComplete={function (): void {
                            // getPRList(page).then((data: PRPage) => {
                            //   setDraftData(data.data);
                            //   setTotalRecords(data.pr_total_count);
                            //   setOpenSnackbar(true); // Snackbarを表示
                            // });
                          }}
                        />
                      ) : (
                        ""
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={() => setOpenSnackbar(false)}
          message="削除が完了しました"
        />
        <Box
          position={"relative"}
          alignItems={"center"}
          justifyContent={"center"}
          display={"flex"}
          padding={2}
          sx={{ width: "100%" }}
        >
          <span style={{ position: "absolute", left: "16px" }}>
            {isDataAvailable
              ? `${Math.min(
                (page - 1) * rowsPerPage + 1,
                totalRecords
              )}から${Math.min(page * rowsPerPage, totalRecords)}件表示`
              : "データ読み込み中..."}
          </span>
          <Pagination
            count={pageCount}
            page={page}
            onChange={(event, newPage) => {
              setPage(newPage);
            }}
            shape="rounded"
            color="primary"
            showFirstButton
            showLastButton
          />
        </Box>
      </TableContainer>
    </PageContainer>
  );
};
export default DraftList;
