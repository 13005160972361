import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import AuthContainer from "../components/authContainer";
import { insertKomuten } from "../api/komuten";
// import { signup } from "../api/auth";

const AuthSignup: React.FC = () => {
  const navigate = useNavigate();
  const [accountId, setAccountId] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const togglePasswordVisibility = () => setShowPassword(!showPassword);
  const toggleConfirmPasswordVisibility = () => setShowConfirmPassword(!showConfirmPassword);

  const validatePassword = () => {
    return (
      password === confirmPassword && password.length >= 6 && confirmPassword.length >= 6
    );
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setIsLoading(true);
    if (!validatePassword()) {
      setPasswordError(true);
      alert("パスワードが一致しないか、6文字未満です。");
      return;
    }

    try {

      await insertKomuten(accountId, password);
      alert('アカウント登録完了');
      navigate("/signin");
    } catch (error) {
      console.error(error);
      // エラーメッセージをユーザーに伝える
      alert("エラーが発生しました。もう一度お試しください。");
    } finally {
      setIsLoading(false);
    }
  };

  // サインイン画面に戻る関数
  const navigateToSignIn = () => {
    navigate("/signin"); // サインイン画面のパスを指定
  };

  // const handleResendEmail = async () => {
  //   try {
  //     // 入力されたメールアドレスに認証メールを再送
  //     const message = await resendConfirmationEmail(accountId);
  //     alert(message); // 成功メッセージを表示
  //   } catch (error) {
  //     alert(error.message); // エラーメッセージを表示
  //   }
  // };

  return (
    <AuthContainer>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          alignContent: "center",
          height: "85vh",
        }}
      >
        <Card
          elevation={3}
          sx={{ margin: "auto", display: "flex", flexDirection: "column" }}
        >
          <Box
            width={"540px"}
            paddingInline={2}
            paddingBlock={4}
            display={"flex"}
            flexDirection={"column"}
            gap={2}
          >
            <Typography variant="h5" paddingBlock={2}>
              サインアップ
            </Typography>
            <form onSubmit={handleSubmit}>
              <Box display={"flex"} flexDirection={"column"} gap={2}>
                <TextField
                  fullWidth
                  value={accountId}
                  onChange={(e) => setAccountId(e.target.value)}
                  label="ログインID"
                  type="accountId"
                />
                <TextField
                  fullWidth
                  type={showPassword ? "text" : "password"}
                  onChange={(e) => setPassword(e.target.value)}
                  label="パスワード"
                  value={password}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={togglePasswordVisibility} edge="end">
                          {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  fullWidth
                  type={showConfirmPassword ? "text" : "password"}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  label="パスワード確認"
                  value={confirmPassword}
                  error={passwordError}
                  helperText={passwordError && "パスワードが一致しないか、6文字未満です。"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={toggleConfirmPasswordVisibility} edge="end">
                          {showConfirmPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  sx={{ height: "42px", mt: 2 }}
                >
                  {isLoading ? <CircularProgress size={24} /> : "サインアップ"}
                </Button>
                <Button
                  onClick={navigateToSignIn}
                  sx={{ mt: 2 }}
                  variant="text"
                  fullWidth
                >
                  既にアカウントをお持ちですか？
                </Button>

              </Box>
            </form>
          </Box>
        </Card>
      </Box>
    </AuthContainer>
  );
};

export default AuthSignup;
