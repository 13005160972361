import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { FC, useEffect, useState } from "react";

import {
  Alert,
  AlertTitle,
  Box,
  Button,
  // Card,
  LinearProgress,
  // Step,
  // StepLabel,
  // Stepper,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import {
  // PRFirst,
  // PRLast,
  PRGenerate,
  PRSave,
  PRRecreate,
  PRDetail,
  PRSaved,
} from "../../types/sakuzyoyotei/pr";
import { useLocation, useNavigate } from "react-router-dom";
import { PATHS } from "../../paths";
import { recreate, savePR } from "../../api/sakuzyoyotei/pr";
import { InputMode } from "../../types/inputMode";
import LoadingButton from "@mui/lab/LoadingButton";
import DraftDetailStepper from "./draftDetailStepper";
import { VisibilityOutlined } from "@mui/icons-material";

interface GenerateDialogProps {
  detail: PRDetail | undefined;
  title: string;
  contentBefore: string;
  hint: string;
  recreate_part_parent: string;
  recreate_part_child: string;
  setValue: (value: string) => void;
}

function GenerateDialog(props: GenerateDialogProps): JSX.Element {
  const { title, hint, contentBefore } = props;
  const [open, setOpen] = React.useState(false);
  const [demand, setDemand] = React.useState("");
  const [isProcessing, setIsProcessing] = useState(false); // 処理中状態の追加
  const [isError, setIsError] = useState(false); // エラー状態の追加
  const [processingDialogOpen, setProcessingDialogOpen] = useState(false);

  const handleClickOpen = () => {
    if (contentBefore === "") {
      setIsError(true); // 修正前のテキストが空欄の為、再生成できません。手動でも入力可能です。
      return;
    }

    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setIsError(false);
  };

  return (
    <React.Fragment>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        width={"100%"}
      >
        <Typography variant="h6">{title}</Typography>
        <Button variant="outlined" onClick={handleClickOpen}>
          再生成する
        </Button>
      </Box>
      {/* アラート用のダイアログ */}
      <Dialog open={isError} onClose={() => setIsError(false)}>
        <DialogTitle>{"エラー"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            修正前のテキストが空欄の為、再生成できません。
            <br />
            手動でも入力可能です。
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsError(false)} color="primary" autoFocus>
            閉じる
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle>
          <Typography variant="h5">再生成</Typography>
          <Typography variant="body2">
            再生成に必要な情報を入力してください
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ display: "grid", rowGap: 2 }}>
          <Typography variant="h6">修正前のテキスト</Typography>
          <DialogContentText>
            <Typography variant="body2">項目名</Typography>
          </DialogContentText>
          <Typography variant="body1">{title}</Typography>
          <DialogContentText>
            <Typography variant="body2">テキスト</Typography>
          </DialogContentText>
          <Typography variant="body1">{contentBefore}</Typography>
          <Typography variant="h6">修正してほしい内容</Typography>
          <Alert severity="info">
            <AlertTitle>再生成のヒント</AlertTitle>
            <Typography variant="body2" whiteSpace={"pre-wrap"}>
              {hint}
            </Typography>
          </Alert>
          <TextField
            value={demand}
            onChange={(e) => setDemand(e.target.value)}
            fullWidth
            multiline
            minRows={5}
          ></TextField>
        </DialogContent>
        <DialogActions>
          <Button fullWidth onClick={handleClose} variant="text">
            キャンセル
          </Button>
          <LoadingButton
            loading={isProcessing}
            fullWidth
            onClick={() => {
              setProcessingDialogOpen(true);
              setIsProcessing(true);

              const prRecreate: PRRecreate = {
                title: props.detail?.data.first.title ?? "",
                table_of_contents:
                  props.detail?.data.first.table_of_contents ?? {},
                summary: props.detail?.data.first.summary ?? "",
                details: props.detail?.data.first.details ?? {},
                demand: demand,
                category: props.detail?.data.request.category ?? 0,
                // category: 0,
                recreate_part: {
                  parent: props.recreate_part_parent,
                  child: props.recreate_part_child,
                },
              };

              console.log(prRecreate);

              recreate(prRecreate)
                .then((res) => {
                  console.log(res);
                  var content = "" as string;

                  if (res.data.recreate_part.parent === "title") {
                    // TypeScriptの型アサーションを使用して適切な型を指定
                    const contentArray = res.data.content as {
                      title: string;
                    }[];
                    content = contentArray.map((item) => item.title).join("\n\n");
                    console.log("content1");
                    console.log(content);
                  } else {
                    console.log("content2");

                    // res.data.contentが文字列の場合の処理
                    content = res.data.content as unknown as string;
                  }

                  props.setValue(content);
                  setIsProcessing(false); // エラー時も処理完了
                  handleClose();
                })
                .catch((err) => {
                  console.error(err);
                })
                .finally(() => {
                  setIsProcessing(false);
                  setProcessingDialogOpen(false);
                });
            }}
            variant="contained"
          >
            生成する
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <Dialog
        open={processingDialogOpen}
        onClose={() => { }}
        disableEscapeKeyDown={true}
      >
        <DialogTitle>処理中</DialogTitle>
        <DialogContent>
          <DialogContentText>
            1分ほどかかります。画面を閉じないでください。
          </DialogContentText>
          <LinearProgress />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

export type DraftDetailEditProps = {
  data: PRDetail | undefined;
  // data: PRFirst | undefined;
  generate: PRGenerate | null;
  handleTabChange: (tabId: string) => void;
  setSavedPR: (saved: PRSaved) => void;
};

const DraftDetailEdit: FC<DraftDetailEditProps> = (props) => {
  const [title, setTitle] = useState("");
  const [overview, setOverview] = useState("");
  const [sectionTitles, setSectionTitles] = useState<Record<string, string>>(
    {}
  );
  const [sectionDetails, setSectionDetails] = useState<Record<string, string>>(
    {}
  );
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const mode = queryParams.get("mode") as InputMode;
  const [previewOpen, setPreviewOpen] = useState(false); // プレビューダイアログの表示制御用

  // コンポーネントの初期化時にdataから初期値を設定
  useEffect(() => {
    console.log(props.data);
    console.log(props.generate);

    if (mode === InputMode.CREATE) {
      setPreviewOpen(true);
    }

    if (props.data?.data.last) {
      // lastからデータを取得
      setTitle(props.data?.data.last.title || "");
      setOverview(props.data?.data.last.summary || "");
      setSectionDetails(props.data?.data.last.details);
      setSectionTitles(props.data?.data.last.table_of_contents);
    }
  }, [mode, props.data, props.generate]);

  const handleSectionChange = (key: string, value: string) => {
    const updatedSections = { ...sectionDetails, [key]: value };
    setSectionDetails(updatedSections);
  };

  const addSection = () => {
    const newSectionIndex = Object.keys(sectionTitles).length + 1;
    const newSectionKey = `section${newSectionIndex}`;

    setSectionTitles((prevTitles) => ({
      ...prevTitles,
      [newSectionKey]: "",
    }));

    setSectionDetails((prevDetails) => ({
      ...prevDetails,
      [newSectionKey]: "",
    }));
  };

  function setPRSave(status: string) {
    const prSave: PRSave = {
      pr_input_id: props.data?.pr_input_id ?? 0,
      gpt_response_id: props.data?.gpt_response_id ?? 0,
      category: props.data?.data.request.category ?? 0,
      title: title,
      summary: overview,
      table_of_contents: sectionTitles,
      details: sectionDetails,
      status: status,
      release_time: Date.now().toString(),
      create_datetime: Date.now().toString(),
      update_datetime: Date.now().toString(),
    };

    return prSave;
  }

  const navigate = useNavigate();

  return (
    <Box
      flexGrow={1}
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"flex-start"}
      alignItems={"flex-start"}
      rowGap={2}
      padding={2}
    >
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        width={"100%"}
      >
        <Typography variant="h5">プレスリリース編集</Typography>

        <Box
          display={"flex"}
          justifyContent={"flex-end"}
          alignItems={"center"}
          columnGap={1}
        >
          <Button
            variant="text"
            onClick={() => {
              props.handleTabChange("create");
            }}
            sx={{ color: "grey" }}
          >
            やり直す
          </Button>
          <Button
            variant="outlined"
            onClick={() => setPreviewOpen(true)}
            endIcon={<VisibilityOutlined />}
          >
            イメージ確認
          </Button>

          <Button
            variant="outlined"
            onClick={() => {
              const prSave: PRSave = setPRSave("draft");

              savePR(prSave).then((res) => {
                props.setSavedPR(res);
                navigate(PATHS.DRAFT);
              });
            }}
          >
            下書き保存
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              const prSave: PRSave = setPRSave("release");

              console.log(prSave);

              savePR(prSave).then((res) => {
                console.log(res);
                props.setSavedPR(res);
                props.handleTabChange("preview");
                navigate(
                  `${PATHS.DRAFT}/${res.data.pr_id}?mode=${InputMode.UPDATE}&tab=preview`
                );
              });
            }}
          >
            内容を確定する
          </Button>
        </Box>
      </Box>
      <Box
        display={
          props.generate?.daily_limit && props.generate.daily_limit < 10
            ? "block"
            : "none"
        }
      >
        <Alert severity="error" sx={{ width: "100%" }}>
          今月のプロンプトの再生成可能件数が、残り10回以下となっています。
        </Alert>
      </Box>
      <Dialog
        open={previewOpen}
        onClose={() => setPreviewOpen(false)}
        fullWidth
        maxWidth="md"
      >
        {/* <DialogTitle>プレスリリース プレビュー</DialogTitle> */}
        <DialogContent>
          <Box
            width="100%"
            display={"flex"}
            flexDirection={"column"}
            rowGap={2}
          >
            <Typography variant="h6" fontWeight={"bold"}>
              {props.data?.data.last.title}
            </Typography>
            <Typography whiteSpace={"pre-wrap"}>
              {props.data?.data.last.summary}
            </Typography>
            {Object.entries(sectionTitles).map(([key, title], index) => (
              <Box key={key} marginTop={2}>
                <Typography
                  variant="subtitle1"
                  gutterBottom
                  fontWeight={"bold"}
                >
                  {title}
                </Typography>
                <Typography paragraph>
                  {sectionDetails[key]} {/* セクション詳細 */}
                </Typography>
              </Box>
            ))}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setPreviewOpen(false)}>閉じる</Button>
        </DialogActions>
      </Dialog>
      <DraftDetailStepper activeStep={1} />
      {/* タイトルブロック Start */}

      <GenerateDialog
        title="タイトル"
        hint={`・文体の修正などは、再生成するよりも入力・編集する方が早い場合があります。\n・追加情報はここで入れると変更しやすいです`}
        contentBefore={title}
        recreate_part_parent="title"
        recreate_part_child=""
        detail={props.data}
        setValue={setTitle}
      />
      <TextField
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        fullWidth
        multiline
        minRows={10}
      ></TextField>
      {/* タイトルブロック End */}
      {/* 概要ブロック Start */}
      <GenerateDialog
        title="" // 概要
        hint={`・文体の修正などは、再生成するよりも入力・編集する方が早い場合があります。\n・追加情報はここで入れると変更しやすいです`}
        contentBefore={overview}
        recreate_part_parent="overview"
        recreate_part_child=""
        detail={props.data}
        setValue={setOverview}
      />
      <TextField
        value={overview}
        onChange={(e) => setOverview(e.target.value)}
        fullWidth
        multiline
        minRows={10}
      ></TextField>
      {/* 概要ブロック End */}
      {/* セクションブロック Start */}
      {Object.entries(sectionTitles).map(([key, title], _) => (
        <Box
          key={key}
          flexGrow={1}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"flex-start"}
          alignItems={"flex-start"}
          rowGap={2}
          width={"100%"}
        >
          <GenerateDialog
            title={title}
            hint={`・箇条書きで記載を推奨しています`}
            contentBefore={sectionDetails[key]}
            recreate_part_parent={title}
            recreate_part_child={sectionDetails[key]}
            detail={props.data}
            setValue={(value) => handleSectionChange(key, value)}
          />
          <TextField
            fullWidth
            multiline
            minRows={10}
            value={sectionDetails[key]}
            onChange={(e) => handleSectionChange(key, e.target.value)}
          ></TextField>
        </Box>
      ))}
      <Box display="flex" justifyContent="flex-end" width={"100%"}>
        <Button variant="outlined" onClick={addSection}>
          セクションの追加
        </Button>
      </Box>
      {/* セクションブロック End */}
    </Box>
  );
};
export default DraftDetailEdit;
