function openCenteredPopup(
  url: string | URL | undefined,
  width: number,
  height: number
) {
  const screenX = window.screenX || window.screenLeft;
  const screenY = window.screenY || window.screenTop;

  const screenWidth =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    window.screen.width;
  const screenHeight =
    window.innerHeight ||
    document.documentElement.clientHeight ||
    window.screen.height;

  const centerLeft = screenX + (screenWidth - width) / 2;
  const centerTop = screenY + (screenHeight - height) / 2;

  const windowFeatures = `width=${width},height=${height},menubar=no,toolbar=no,left=${centerLeft},top=${centerTop}`;

  window.open(url, "_blank", windowFeatures);
}

export default openCenteredPopup;
