import { FC, useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  List,
  DialogContent,
  DialogActions,
  Dialog,
  DialogTitle,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
  FormControl,
  TableContainer,
  TableHead,
  Pagination,
  Paper,
  DialogContentText,
  IconButton,
  CircularProgress, Backdrop
} from "@mui/material";
import { Delete } from '@mui/icons-material';
import React from "react";
import PageContainer from "../components/pageContainer";
import SubMenuItem from "../components/menuItem";
import { useLocation, useNavigate } from "react-router-dom";
import { InputMode } from "../types/inputMode";
import { useRecoilState, useRecoilValue } from 'recoil';
import { komutenState } from "../recoil/komutenState";
import { getKomutenData } from "../service/komutenService";
import { updateKomutenInfo, updateKomutenPassword } from "../api/komuten";
import { deletePointGetWay, getPointGetWay, insertPointGet } from "../api/pointGet";
import { getFileUrl, removeFile, uploadFile } from "../service/storageService";
import { deleteBannerMetadata, fetchBanner, insertBannerMetadata, updateBannerMetadata } from "../api/banner";
import { Banner } from "../types/banner";
import PasswordChangeDialog from "../components/passwordChangeDialog";
import { fetchKomutenDocuments } from "../api/syorui";

interface PointRankOption {
  points: number;
  rank: string;
}

type SelectedFileWithIndex = {
  matchingIndex: number;
  file: File;
};

//基本情報
const BasicInfo: React.FC = () => {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [name, setName] = useState("");
  const [name_kana, setNameKana] = useState("");
  const [email, setEmail] = useState("");
  const [tell_number, setTellNumber] = useState("");
  const [point_rate, setPointRate] = useState("");
  const [created_at, setCreatedAt] = useState<Date | null>(null);
  const [updated_at, setUpdatedAt] = useState<Date | null>(null);
  const [mode, setMode] = useState<InputMode>(InputMode.READ);
  const [errorText, setErrorText] = useState<string | null>(null);
  const [errorIndex, setErrorIndex] = useState<number | null>(null);
  const [images, setImages] = useState<Array<{ url: string | ArrayBuffer | null, hp_url: string | null }>>([]);
  const [selectedFiles, setSelectedFiles] = useState<SelectedFileWithIndex[]>([]);
  const [defaultBannerList, setDefaultBannerList] = useState<Banner[]>([]);
  const [selectIndexList, setSelectIndexList] = useState<number[]>([]);
  const [komuten, setKomuten] = useRecoilState(komutenState);
  const [isLoading, setIsLoading] = useState(false);
  const [hpUrls, setHpUrls] = useState<{ [index: number]: string }>({});
  const [defaultHpUrls, setDefaultHpUrls] = useState<{ [index: number]: string }>({});
  const [openDialog, setOpenDialog] = useState(false);
  const [storageUsage, setStorageUsage] = useState(0);

  const fetchBannerList = useCallback(async () => {
    try {
      if (!komuten) {
        return;
      }
      const banners = await fetchBanner(komuten.id);

      if (banners.length === 0) {
        return;
      }
      const bannerDataList = await Promise.all(
        banners.map(async (doc: { file_path: string; hp_url: string; }) => {
          try {
            const url = await getFileUrl('banner', doc.file_path);
            // hp_url を含むオブジェクトを返す
            return { url, hp_url: doc.hp_url };
          } catch (error) {
            console.error('Error fetching file URL:', error);
            return { url: '', hp_url: doc.hp_url, error: 'Failed to load document URL' };
          }
        })
      );
      setImages(bannerDataList);
      setHpUrls(bannerDataList.reduce((acc, curr, index) => {
        acc[index] = curr.hp_url;
        return acc;
      }, {} as { [index: number]: string }));

      setDefaultHpUrls(bannerDataList.reduce((acc, curr, index) => {
        acc[index] = curr.hp_url;
        return acc;
      }, {} as { [index: number]: string }));

      setDefaultBannerList(banners);

    } catch (error) {
      console.error('Error fetchBannerList:', error);
      alert('データの読み込みに失敗しました。もう一度お試しください。');
    }
  }, [komuten]);

  useEffect(() => {
    const komouten_uid = komuten ? komuten.komuten_uid : "";
    const komuten_id = komuten ? komuten.id : 0;
    fetchKomuten(komouten_uid);
    fetchBannerList();
    storageCalculation(komuten_id);
    if (komuten?.banner_number) {
      setImages(Array(komuten.banner_number).fill(null));
    }
  }, [komuten, fetchBannerList]);


  const fetchKomuten = async (komuten_uid: string) => {
    const { data, error } = await getKomutenData(komuten_uid);
    if (error) {
      alert(`エラーが発生しました。画面の読み込みを再度行ってみてください。`);
      return;
    }
    if (data) {
      setName(data.name || "");
      setNameKana(data.name_kana || "");
      setEmail(data.email || "");
      setTellNumber(data.tell_number || "");
      setPointRate(data.point_rate || "");
      setPointRankOptions(data.point_rank || []);
      setCreatedAt(data.created_at ? new Date(data.created_at) : null);
      setUpdatedAt(data.updated_at ? new Date(data.updated_at) : null);
    }
  };

  const storageCalculation = async (komuten_id: number) => {
    try {
      const data = await fetchKomutenDocuments(komuten_id);
      if (data) {
        const totalSize = data.reduce((acc: number, doc: { file_size: number; }) => acc + doc.file_size, 0);
        setStorageUsage(totalSize);
      }
    } catch (error) {
      console.error('Error fetchKomutenDocuments:', error);
      alert('データの読み込みに失敗しました。もう一度お試しください。');
    }
  };

  const handleCancel = () => {
    // 編集キャンセル処理
    setMode(InputMode.READ);
  };

  function validatePointRankOptions() {
    for (let i = 1; i < pointRankOptions.length; i++) {
      if (pointRankOptions[i].points <= pointRankOptions[i - 1].points) {
        setErrorText('各ポイントランクのポイントは前のランクより大きくなければなりません。');
        setErrorIndex(i); // エラーがある要素のインデックスを設定
        return false; // エラーが見つかったら、さらなる処理を停止
      }
    }
    // すべての検証をパスした場合はtrueを返す
    return true;
  }

  //工務店情報更新(komutenテーブル)
  async function updateKomutenInformation() {
    const newKomutenData = { id: komuten?.id, name, name_kana, email, tell_number, point_rate, point_rank: pointRankOptions };
    await updateKomutenInfo(newKomutenData);
  }

  const handleSave = async () => {
    try {
      setErrorIndex(null);

      if (!validatePointRankOptions()) {
        return;
      }

      await updateKomutenInformation();
      // バナー画像,hpUrlが変更されていない場合は、処理を終了
      if (selectedFiles.length === 0 && objectsAreEqual(hpUrls, defaultHpUrls)) {
        const komouten_uid = komuten ? komuten.komuten_uid : "";
        fetchKomuten(komouten_uid);
        updateKomuten(komouten_uid);
        setOpenSnackbar(true);
        setErrorText(null); // エラーメッセージをクリア
        setMode(InputMode.READ);
        return;
      }

      let uploadPath = null;
      try {
        let insertedIndexList: number[] = [];
        if (selectedFiles.length !== 0) {
          // バナー画像が選択されている場合
          setIsLoading(true);
          for (const selectedFile of selectedFiles) {
            //初めての登録の場合
            if (defaultBannerList.length === 0 && defaultBannerList.length === 0) {
              //初めて登録する場合
              const filePath = `${komuten!.id}/${komuten!.id}_${Date.now()}_${selectedFile.matchingIndex}`;
              uploadPath = await uploadFile('banner', filePath, selectedFile.file);
              const registerHpUrl = hpUrls[selectedFile.matchingIndex] === undefined ? "" : hpUrls[selectedFile.matchingIndex];
              await insertBannerMetadata(komuten!.id, uploadPath.Key, selectedFile.matchingIndex, selectedFile.file.name, registerHpUrl);
              //インサートしたインデックスを保存
              insertedIndexList.push(selectedFile.matchingIndex);
              setSelectedFiles([]);
              setSelectIndexList([]);
              continue;
            }

            //既存のバナーが存在する場合
            const matchedBanner = defaultBannerList.find(banner => banner.banner_number === selectedFile.matchingIndex);
            if (matchedBanner) {
              // 既存のバナーが一致する場合は、そのバナーを削除
              await deleteBannerMetadata(matchedBanner.id);
              await removeFile('banner', matchedBanner.file_path);
              // 新しいバナーを追加
              const filePath = `${komuten!.id}/${komuten!.id}_${Date.now()}_${selectedFile.matchingIndex}`;
              uploadPath = await uploadFile('banner', filePath, selectedFile.file);
              await insertBannerMetadata(komuten!.id, uploadPath.Key, selectedFile.matchingIndex, selectedFile.file.name, hpUrls[selectedFile.matchingIndex]);
              //インサートしたインデックスを保存
              insertedIndexList.push(selectedFile.matchingIndex);
            }
          }
          setSelectedFiles([]);
          setSelectIndexList([]);
        }
        if (!objectsAreEqual(hpUrls, defaultHpUrls) && hpUrls !== undefined) {
          //hpUrlのみ変更する場合

          // hpUrlsとdefaultHpUrlsで値が異なるインデックスを探し、何らかの処理を行う
          for (const key of Object.keys(hpUrls)) {
            const index = Number(key); // キーを数値に変換
            //URLが変更されている場合かつ、画像と一緒に登録されていない場合
            if (hpUrls[index] !== defaultHpUrls[index] && !insertedIndexList.includes(index)) {
              if (defaultBannerList.length === 0 || defaultBannerList[index] === undefined) {
                //新規登録の場合(file_path,image_nameは空文字)
                await insertBannerMetadata(komuten!.id, '', index, '', hpUrls[index]);
              } else {
                await updateBannerMetadata(komuten!.id, defaultBannerList[index].banner_number, hpUrls[index]);
              }

            }
          }
        }

        const komouten_uid = komuten ? komuten.komuten_uid : "";
        await fetchKomuten(komouten_uid);
        await fetchBannerList();
        if (komuten?.banner_number) {
          setImages(Array(komuten.banner_number).fill(null));
        }
        await updateKomuten(komouten_uid);
        setOpenSnackbar(true);
        setErrorText(null);
        setMode(InputMode.READ);
      } catch (error) {
        console.error('Error:', error);
        alert('処理に失敗しました。');
        if (uploadPath && uploadPath.Key) {
          try {
            await removeFile('banner', uploadPath.Key);
          } catch (removeError) {
            console.error('Failed to remove uploaded file:', removeError);
          }
        }
      }
    } catch (error) {
      // エラーをユーザーに伝える
      alert('更新に失敗しました。もう一度お試しください。');
    } finally {
      setIsLoading(false); // 処理が終了したらローディング状態を解除
    }
  };

  // オブジェクトの内容が等しいかどうかをチェックする関数
  function objectsAreEqual(obj1: { [x: string]: any;[x: number]: string; }, obj2: { [x: string]: any;[x: number]: string; hasOwnProperty?: any; }) {
    const obj1Keys = Object.keys(obj1);
    const obj2Keys = Object.keys(obj2);

    // キーの数が異なる場合は、オブジェクトは等しくない
    if (obj1Keys.length !== obj2Keys.length) {
      return false;
    }
    // オブジェクトの各キーに対して、値が等しいかどうかをチェック
    return obj1Keys.every(key => obj2.hasOwnProperty(key) && obj1[key] === obj2[key]);
  }


  const updateKomuten = async (komuten_uid: string) => {
    const { data, error } = await getKomutenData(komuten_uid);
    if (error) {
      alert(`エラーが発生しました。画面の読み込みを再度行ってみてください。`);
      return;
    }
    //recoilの更新
    setKomuten(data);
    //ローカルストレージの情報を更新
    localStorage.setItem('komutenData', JSON.stringify(data));
  };

  const [pointRankOptions, setPointRankOptions] = useState<PointRankOption[]>([
    { points: 0, rank: 'ブルー' },
    { points: 0, rank: 'ブロンズ' },
    { points: 0, rank: 'シルバー' },
    { points: 0, rank: 'ゴールド' },
    { points: 0, rank: 'プラチナ' },
  ]);

  const handleRankChange = (index: number, field: 'points' | 'rank', value: string) => {
    const updatedOptions = [...pointRankOptions];
    if (field === 'points') {
      // 文字列を数値に変換してから設定します。
      const numericValue = parseInt(value, 10);
      updatedOptions[index][field] = !isNaN(numericValue) ? numericValue : 0; // NaN の場合は 0 を設定
    } else {
      updatedOptions[index][field] = value;
    }
    setPointRankOptions(updatedOptions);
  };

  const handleHpUrlChange = (index: number, newHpUrl: string) => {
    setHpUrls(prev => ({ ...prev, [index]: newHpUrl }));
  };

  const handlePhoneChange = (setter: (value: string) => void) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const formattedValue = value.replace(/[^0-9-]/g, "");
    setter(formattedValue);
  };

  // 画像ファイルが選択されたときの処理
  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];

      const reader = new FileReader();
      reader.onload = (e) => {
        const newImages = [...images];
        let newSelectedFiles = [...selectedFiles];

        if (typeof e.target?.result === 'string') {
          // 既存のデータがあるか確認し、なければ新しく作成
          if (!newImages[index]) {
            newImages[index] = { url: e.target.result, hp_url: null };
          } else {
            newImages[index].url = e.target.result;
          }
          setImages(newImages);
          const existingFileIndex = selectedFiles.findIndex(f => f.matchingIndex === index);
          const matchingIndex = index;
          if (selectIndexList.includes(index)) {
            // 既存のファイルがあれば、それを更新
            newSelectedFiles[existingFileIndex] = { matchingIndex, file };
          } else {
            // 新しいファイルが選択された場合は、配列に追加
            newSelectedFiles.push({ matchingIndex, file });
            selectIndexList.push(index);
          }
          setSelectedFiles(newSelectedFiles); // ファイルステートの更新
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleChangePassword = async (newPassword: string) => {
    try {
      await updateKomutenPassword(komuten!.account_id, newPassword);
      alert('パスワードが変更されました。');
    } catch (error) {
      console.error(error);
      // エラーメッセージをユーザーに伝える
      alert("エラーが発生しました。もう一度お試しください。");
    }
  };

  const formatStorageUsage = (bytes: number) => {
    const MB = bytes / (1000 * 1000);
    if (MB < 1000) {
      return `${MB.toFixed(2)} MB`;
    } else {
      const GB = MB / 1000;
      return `${GB.toFixed(2)} GB`;
    }
  };

  return (
    <>
      <Backdrop open={isLoading} style={{ zIndex: 9999, color: '#fff' }}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box padding={1} width={"100%"}>
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
          marginBottom={1}
        >
          <Typography variant="h5">基本情報</Typography>
          <Box
            display={"flex"}
            justifyContent={"flex-end"}
            gap={2}
          >
            {mode === InputMode.READ && (
              <Button
                variant="outlined"
                onClick={() => setMode(InputMode.UPDATE)}
              >
                編集
              </Button>
            )}
            {mode === InputMode.UPDATE && (
              <>
                <Button
                  variant="outlined"
                  onClick={handleCancel}
                >
                  キャンセル
                </Button>
                <Button
                  variant="contained"
                  onClick={handleSave}
                >
                  保存
                </Button>
              </>
            )}
          </Box>
        </Box>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell sx={{ width: '200px' }}>企業名</TableCell>
              <TableCell >
                {mode === InputMode.UPDATE ? (
                  <TextField
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    fullWidth
                  />
                ) : (
                  name
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ width: '200px' }}>ふりがな</TableCell>
              <TableCell >
                {mode === InputMode.UPDATE ? (
                  <TextField
                    value={name_kana}
                    onChange={(e) => setNameKana(e.target.value)}
                    fullWidth
                  />
                ) : (
                  name_kana
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ width: '200px' }}>メールアドレス</TableCell>
              <TableCell >
                {mode === InputMode.UPDATE ? (
                  <TextField
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    fullWidth
                  />
                ) : (
                  email
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ width: '200px' }}>電話番号</TableCell>
              <TableCell >
                {mode === InputMode.UPDATE ? (
                  <TextField
                    value={tell_number}
                    onChange={handlePhoneChange(setTellNumber)}
                    fullWidth
                    inputProps={{ pattern: "[0-9-]*" }}
                  />
                ) : (
                  tell_number
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ width: '200px' }}>ポイント還元率</TableCell>
              <TableCell >
                {mode === InputMode.UPDATE ? (
                  <TextField
                    value={point_rate}
                    onChange={(e) => setPointRate(e.target.value)}
                    fullWidth
                  />
                ) : (
                  point_rate
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ width: '200px' }}>ポイントランク</TableCell>
              <TableCell>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                  {pointRankOptions.map((option, index) => (
                    <Box key={index} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, justifyContent: 'flex-start' }}>
                        {mode === InputMode.UPDATE ? (
                          <>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <Box sx={{ flexShrink: 0 }}>
                                {index === 0 ? '' : '累積'}
                              </Box>
                              <Box sx={{ flexShrink: 0 }}>
                                {index === 0 ? 'スタート' :
                                  <Box sx={{ p: 1 }}>
                                    <TextField
                                      value={option.points.toString()}
                                      onChange={(e) => handleRankChange(index, 'points', e.target.value)}
                                      size="small"
                                      error={index !== 0 && errorIndex === index} // エラーを表示する条件
                                    />
                                  </Box>
                                }
                              </Box>
                              <Box sx={{ flexShrink: 0 }}>
                                {index === 0 ? '：' : 'pt以上：'}{option.rank}
                              </Box>
                            </Box>
                          </>
                        ) : (
                          <>
                            <Box sx={{ display: 'flex', width: '100%' }}>
                              <Box sx={{ flexShrink: 0, marginRight: 2 }}>
                                {index === 0 ? 'スタート：' : `累積${Number(option.points).toLocaleString()}pt以上：`}
                              </Box>
                              <Box sx={{ flexGrow: 1, flexBasis: 0 }}>
                                {option.rank}
                              </Box>
                            </Box>
                          </>
                        )}
                      </Box>
                      {index !== 0 && errorIndex === index && mode === InputMode.UPDATE && (
                        <Typography sx={{ color: 'red', fontSize: '0.75rem', mt: -2, ml: 2 }}>{errorText}</Typography>
                      )}
                    </Box>
                  ))}
                </Box>
              </TableCell>
            </TableRow>
            {
              images.slice(0, komuten?.banner_number || 1).map((banner, index) => (
                <TableRow key={index}>
                  <TableCell>ホームページバナー画像 {index + 1}</TableCell>
                  <TableCell>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row', // コンテンツを横方向に配置
                        alignItems: 'center', // 中央寄せ
                        gap: '10px', // 画像とボタンの間隔
                      }}
                    >
                      {mode === InputMode.UPDATE && (
                        <>
                          {banner && banner.url && banner.url !== 'https://bxhujxrrnwuwdffcbyhd.supabase.co/storage/v1/object/public/banner/' ? (
                            <img
                              style={{
                                height: 150,
                                width: 270,
                                objectFit: 'contain',
                              }}
                              alt={`Preview ${index + 1}`}
                              src={banner.url.toString()}
                            />
                          ) : (
                            <Typography variant="body2">画像が設定されていません</Typography>
                          )}

                          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                            <input
                              accept="image/*"
                              type="file"
                              id={`image-input-${index}`}
                              style={{ display: 'none' }}
                              onChange={(e) => handleImageChange(e, index)}
                            />
                            <label htmlFor={`image-input-${index}`}>
                              <Button variant="contained" component="span">
                                画像を選択
                              </Button>
                            </label>
                            {/* 画像の推奨サイズを記載 */}
                            <Typography variant="body2" color="textSecondary">
                              推奨サイズ: 縦300px・横540px
                            </Typography>
                          </Box>
                        </>
                      )}
                      {mode !== InputMode.UPDATE && banner && banner.url && banner.url !== '' && banner.url !== 'https://bxhujxrrnwuwdffcbyhd.supabase.co/storage/v1/object/public/banner/' && (
                        <img
                          style={{
                            height: 150,
                            width: 270,
                            objectFit: 'contain',
                          }}
                          alt={`Preview ${index + 1}`}
                          src={banner.url.toString()}
                        />
                      )}
                    </Box>
                    {/* hp_url の表示 */}
                    {mode === InputMode.UPDATE ? (
                      <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        value={hpUrls[index] || ''}
                        onChange={(e) => handleHpUrlChange(index, e.target.value)}
                        sx={{ alignSelf: 'flex-start', mt: 2 }} // 上のマージンを設定して間隔を空ける
                        placeholder="ホームページURLを入力してください"
                      />
                    ) : (
                      banner && banner.hp_url && (
                        <Typography variant="body2" sx={{ alignSelf: 'flex-start', mt: 2 }}>{banner.hp_url}</Typography> // 上のマージンを設定して間隔を空ける
                      )
                    )}
                  </TableCell>

                </TableRow>
              ))
            }
            <TableRow>
              <TableCell sx={{ width: '200px' }} >データ使用量</TableCell>
              <TableCell >
                <Typography>{formatStorageUsage(storageUsage)}/{formatStorageUsage(komuten!.storage_size)}</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ width: '200px' }} >更新日</TableCell>
              <TableCell >
                <Typography>{updated_at ? updated_at.toLocaleDateString() : '未設定'}</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ width: '200px' }} >登録日</TableCell>
              <TableCell >
                <Typography>{created_at ? created_at.toLocaleDateString() : '未設定'}</Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        {/* パスワード変更ボタンとダイアログ、条件付きで表示 */}
        {mode === InputMode.READ && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-end',
              marginTop: 2 // トップからのマージンを調整
            }}
          >
            <Button variant="contained" onClick={handleOpenDialog} sx={{ mb: 1 }}>パスワード変更</Button>
            <PasswordChangeDialog
              open={openDialog}
              onClose={handleCloseDialog}
              onChangePassword={handleChangePassword}
            />
          </Box>
        )}
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={() => setOpenSnackbar(false)}
          message="情報が更新されました"
        />
      </Box >
    </>
  );
};


//ポイント
const PointGetWay: React.FC = () => {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const rowsPerPage = 10;
  const [page, setPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [pointGetWayList, setPointGetWayList] = useState<any[]>([]);
  const komuten = useRecoilValue(komutenState);

  const fetchPointGetWayList = useCallback(async () => {
    const komouten_uid = komuten ? komuten.komuten_uid : "";
    const { data, totalCount, error } = await getPointGetWay(komouten_uid, page, rowsPerPage);
    if (error) {
      console.error(error);
      alert('データの取得時にエラーが発生しました。もう一度お試しください。');
    } else {
      setPointGetWayList(data || []);
      setTotalRecords(totalCount || 0);
    }
  }, [komuten, page, rowsPerPage]);

  useEffect(() => {
    fetchPointGetWayList();
  }, [fetchPointGetWayList]);

  //新規登録
  const [open, setOpen] = useState(false);
  const [newPoint, setNewPoint] = useState('');
  const [way, setPointContent] = useState('');
  // 新規登録用のステート
  const [newError, setError] = useState({
    newGetway: '',
    newGetPoint: '',
  });
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // 新規登録処理を実装します
  const handleSubmit = async () => {
    if (!validateForm()) {
      return;
    }
    try {
      const komouten_uid = komuten ? komuten.komuten_uid : "";
      await insertPointGet
        (
          komouten_uid,
          way,
          newPoint
        );

      //画面更新
      await fetchPointGetWayList()

      setOpenSnackbar(true);
    } catch (error) {
      console.error('Error:', error);
      alert('処理に失敗しました。');
    }
    handleClose();
  };

  const validateForm = () => {
    let isValid = true;
    let errors = { newGetway: '', newGetPoint: '' };

    if (!way.trim()) {
      errors.newGetway = 'ポイント獲得方法を入力してください。';
      isValid = false;
    }
    if (!newPoint.trim()) {
      errors.newGetPoint = 'ポイント獲得数を入力してください。';
      isValid = false;
    }

    setError(errors);
    return isValid;
  };

  // レコード削除処理
  const handleDelete = async (id: number) => {
    const isDeleted = await deletePointGetWay(id);
    if (isDeleted) {
      await fetchPointGetWayList(); // データを再取得
      setOpenSnackbar(true); // オプションで成功メッセージを表示
    } else {
      alert('データの削除に失敗しました。');
    }
  };


  return (
    <>
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
      // marginBottom={1}
      >
        <Typography
          variant="h5"
          component="div"
        >
          ポイント
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "flex-end", marginBottom: 1 }}>
          <Button variant="outlined" onClick={handleOpen}>追加</Button>
        </Box>
        <Dialog open={open} onClose={handleClose} fullWidth>
          <DialogTitle>ポイント獲得作成</DialogTitle>
          <DialogContent>
            <DialogContentText>ポイントの獲得方法と付与数を入力してください</DialogContentText>
            <FormControl fullWidth margin="normal" variant="outlined" sx={{ mt: 1 }}>
              <TextField
                error={!!newError.newGetway}
                helperText={newError.newGetway}
                label="獲得方法"
                value={way}
                onChange={(e) => setPointContent(e.target.value)}
                multiline
                rows={2}
                margin="dense"
              />
            </FormControl>
            <FormControl fullWidth margin="normal" variant="outlined" sx={{ mt: 1 }}>
              <TextField
                error={!!newError.newGetPoint}
                helperText={newError.newGetPoint}
                label="ポイント付与数"
                value={newPoint}
                onChange={(e) => setNewPoint(e.target.value)}
                margin="dense"
              />
              <Typography
                variant="caption"
                sx={{ color: 'text.secondary', ml: 1, mt: 1 }}
              >
                例：「3,000pt」「工事規模により」など
              </Typography>
            </FormControl>
          </DialogContent>
          <DialogActions sx={{ flexDirection: 'column', alignItems: 'flex-start', padding: (theme) => theme.spacing(2) }}>
            <Button onClick={handleSubmit} variant="contained" sx={{ width: '100%', mb: 2 }}>作成</Button>
            <Button onClick={handleClose} sx={{ width: '100%' }}>キャンセル</Button>
          </DialogActions>
        </Dialog>
      </Box>
      <TableContainer component={Paper}>
        {pointGetWayList.length === 0 ? (
          <Typography variant="body1" align="center" sx={{ py: 2 }}>
            ポイント獲得方法は未作成です
          </Typography>
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ whiteSpace: "nowrap" }}>獲得方法</TableCell>
                <TableCell sx={{ whiteSpace: "nowrap" }}>付与ポイント数</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pointGetWayList.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>
                    {row.way}
                  </TableCell>
                  <TableCell>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <span>{row.give_point}</span>
                      <IconButton onClick={() => handleDelete(row.id)}>
                        <Delete />
                      </IconButton>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            mt: 2,
            mb: 2
          }}
        >
          <Pagination
            count={Math.ceil(totalRecords / rowsPerPage)}
            page={page}
            onChange={(event, newPage) => {
              setPage(newPage);
            }} color="primary"
            showFirstButton
            showLastButton
          />
        </Box>
      </TableContainer>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        message="情報が更新されました"
      />
    </>
  );
};

export type SettingProps = {};

const Setting: FC<SettingProps> = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const initialContent = queryParams.get("tab") || "BasicInfo";
  const [selectedContent, setSelectedContent] = useState(initialContent);

  // タブを切り替えるための関数
  const handleTabChange = (tabId: string) => {
    setSelectedContent(tabId);
    navigate(`${location.pathname}?tab=${tabId}`);
  };

  // リストアイテムのデータを定義
  const listItems = [
    { id: "BasicInfo", text: "基本情報", component: <BasicInfo /> },
    { id: "PointGetWay", text: "ポイント", component: <PointGetWay /> },
  ];

  useEffect(() => {
    setSelectedContent(initialContent);
  }, [initialContent]);

  return (
    <PageContainer>
      <Box>
        <Typography
          variant="h4"
          component="div"
          sx={{ flexGrow: 1, textAlign: "left", marginBottom: 1 }}
        >
          設定
        </Typography>
      </Box>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        // alignItems={"center"}
        border={1}
        borderColor={"#0000001A"}
      >
        <List
          sx={{
            borderRight: 1,
            borderColor: "#0000001A",
            width: "180px",
            padding: 1,
          }}
        >
          {listItems.map((item) => (
            <SubMenuItem
              key={item.id}
              text={item.text}
              selected={selectedContent === item.id}
              onClick={() => handleTabChange(item.id)}
            />
          ))}
        </List>
        <Box
          flexGrow={1}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"flex-start"}
          // alignItems={"flex-start"}
          rowGap={2}
          padding={2}
        >
          {listItems.find((item) => item.id === selectedContent)?.component}
        </Box>
      </Box>
    </PageContainer>
  );
};
export default Setting;
