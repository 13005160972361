import { FC, useEffect, useState, useCallback } from "react";
import {
  Box,
  Button,
  List,
  DialogContent,
  DialogActions,
  Dialog,
  DialogTitle,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
  TableContainer,
  TableHead,
  Pagination,
  Paper,
  DialogContentText,
  InputLabel,
  Input,
  IconButton,
  FormControl, Radio, RadioGroup
} from "@mui/material";
import { useParams } from "react-router-dom";
import { Download, Delete } from '@mui/icons-material';
import React from "react";
import PageContainer from "../components/pageContainer";
import SubMenuItem from "../components/menuItem";
import { useLocation, useNavigate } from "react-router-dom";
import { InputMode } from "../types/inputMode";
import { PATHS } from "../paths";
import { Customer } from "../types/customer";
import { deletePointHistoryAndUpdateCustomer, insertPointHistoryAndUpdateCustomer, updateCustomerInfo, getCustomer, deleteCustomer } from "../api/customerAndPoint";
import { getFilePublicUrl, getFileUrl, removeFile, uploadFile } from "../service/storageService";
import { deleteDocumentMetadata, fetchCustomerDocuments, fetchKomutenDocuments, insertSyoruiMetadata } from "../api/syorui";
import FormControlLabel from '@mui/material/FormControlLabel';
import { getPointHistory } from "../api/pointHistory";
import { komutenState } from "../recoil/komutenState";
import { useRecoilValue } from "recoil";


interface BasicInfoProps {
  customerDafalutData: Customer | null;
}
//基本情報
const BasicInfo: FC<BasicInfoProps> = ({ customerDafalutData }) => {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [name, setName] = useState("");
  const [name_kana, setFurigana] = useState("");
  const [nick_name, setNickName] = useState("");
  const [post_number, setPostalCode] = useState("");
  const [zyusyo, setAddress] = useState("");
  const [kotei_tell_number, setKoteiTellNumber] = useState("");
  const [tell_number, setTellNumber] = useState("");
  const [kubun, setkubun] = useState("");
  const [tantosya, setTantosya] = useState("");
  const [history, setHistory] = useState("");
  const [created_at, setCreatedAt] = useState<Date | null>(null);
  const [updated_at, setUpdatedAt] = useState<Date | null>(null);
  const [mode, setMode] = useState<InputMode>(InputMode.READ);
  const [setting_state, setSettingState] = useState<boolean>(false);

  useEffect(() => {
    if (customerDafalutData) {
      setName(customerDafalutData.name || "");
      setFurigana(customerDafalutData.name_kana || "");
      setNickName(customerDafalutData.nick_name || "");
      setPostalCode(customerDafalutData.post_number || "");
      setAddress(customerDafalutData.zyusyo || "");
      setKoteiTellNumber(customerDafalutData.kotei_tell_number || "");
      setTellNumber(customerDafalutData.tell_number || "");
      setkubun(customerDafalutData.kubun || "");
      setTantosya(customerDafalutData.tantosya || "");
      setHistory(customerDafalutData.history || "");
      setCreatedAt(customerDafalutData.created_at ? new Date(customerDafalutData.created_at) : null);
      setUpdatedAt(customerDafalutData.updated_at ? new Date(customerDafalutData.updated_at) : null);
      setSettingState(customerDafalutData.setting_state || false);
    }
  }, [customerDafalutData]);

  const handleCancel = () => {
    // 編集キャンセル処理
    setMode(InputMode.READ);
  };

  const handleSave = async () => {
    try {
      const customerData = {
        customer_id: customerDafalutData?.id,
        name,
        name_kana,
        nick_name,
        post_number,
        zyusyo,
        kotei_tell_number,
        tell_number,
        kubun,
        tantosya,
        history,
        setting_state
      };

      await updateCustomerInfo(customerData);

      // 保存後の処理、例えばスナックバーを表示するなど
      setOpenSnackbar(true);
      // モードを読み取り専用に戻す
      setMode(InputMode.READ);
    } catch (error) {
      // エラーをユーザーに通知
      alert(`更新に失敗しました。もう一度お試しください。`);
    }
  };

  const formatPostalCode = (code: string) => {
    if (code && code.length === 7) {
      return `${code.substring(0, 3)}-${code.substring(3)}`;
    }
    return code;
  };

  const handlePhoneChange = (setter: (value: string) => void) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const formattedValue = value.replace(/[^0-9-]/g, "");
    setter(formattedValue);
  };

  const handlePostalCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const formattedValue = value.replace(/[^0-9]/g, ""); // 数字のみ許可
    setPostalCode(formattedValue);
  };

  return (
    <Box padding={1} width={"100%"}>
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
        marginBottom={1}
      >
        <Typography variant="h5">基本情報</Typography>
        <Box
          display={"flex"}
          justifyContent={"flex-end"}
          gap={2}
        >
          {mode === InputMode.READ && (
            <Button
              variant="outlined"
              onClick={() => setMode(InputMode.UPDATE)}
            >
              編集
            </Button>
          )}
          {mode === InputMode.UPDATE && (
            <>
              <Button
                variant="outlined"
                onClick={handleCancel}
              >
                キャンセル
              </Button>
              <Button
                variant="contained"
                onClick={handleSave}
              >
                保存
              </Button>
            </>
          )}
        </Box>
      </Box>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell sx={{ width: '120px' }}>氏名</TableCell>
            <TableCell>
              {mode === InputMode.UPDATE ? (
                <TextField
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  fullWidth
                />
              ) : (
                <Typography>{name}</Typography>
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ width: '120px' }}>ふりがな</TableCell>
            <TableCell>
              {mode === InputMode.UPDATE ? (
                <TextField
                  value={name_kana}
                  onChange={(e) => setFurigana(e.target.value)}
                  fullWidth
                />
              ) : (
                <Typography>{name_kana}</Typography>
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ width: '120px' }}>LINEの名前</TableCell>
            <TableCell>
              {mode === InputMode.UPDATE ? (
                <TextField
                  value={nick_name}
                  onChange={(e) => setNickName(e.target.value)}
                  fullWidth
                />
              ) : (
                <Typography>{nick_name}</Typography>
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ width: '120px' }}>郵便番号</TableCell>
            <TableCell>
              {mode === InputMode.UPDATE ? (
                <TextField
                  value={post_number}
                  onChange={handlePostalCodeChange}
                  fullWidth
                  inputProps={{ pattern: "[0-9]*" }}
                />
              ) : (
                <Typography>{formatPostalCode(post_number)}</Typography>
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ width: '120px' }}>住所</TableCell>
            <TableCell>
              {mode === InputMode.UPDATE ? (
                <TextField
                  value={zyusyo}
                  onChange={(e) => setAddress(e.target.value)}
                  fullWidth
                />
              ) : (
                <Typography>{zyusyo}</Typography>
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ width: '120px' }}>固定電話番号</TableCell>
            <TableCell>
              {mode === InputMode.UPDATE ? (
                <TextField
                  value={kotei_tell_number}
                  onChange={handlePhoneChange(setKoteiTellNumber)}
                  fullWidth
                  inputProps={{ pattern: "[0-9-]*" }}
                />
              ) : (
                <Typography>{kotei_tell_number}</Typography>
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ width: '120px' }}>携帯電話番号</TableCell>
            <TableCell>
              {mode === InputMode.UPDATE ? (
                <TextField
                  value={tell_number}
                  onChange={handlePhoneChange(setTellNumber)}
                  fullWidth
                  inputProps={{ pattern: "[0-9-]*" }}
                />
              ) : (
                <Typography>{tell_number}</Typography>
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ width: '120px' }}>区分</TableCell>
            <TableCell>
              {mode === InputMode.UPDATE ? (
                <TextField
                  value={kubun}
                  onChange={(e) => setkubun(e.target.value)}
                  fullWidth
                />
              ) : (
                <Typography>{kubun}</Typography>
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ width: '120px' }}>担当者</TableCell>
            <TableCell>
              {mode === InputMode.UPDATE ? (
                <TextField
                  value={tantosya}
                  onChange={(e) => setTantosya(e.target.value)}
                  fullWidth
                />
              ) : (
                <Typography>{tantosya}</Typography>
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ width: '120px' }}>履歴</TableCell>
            <TableCell>
              {mode === InputMode.UPDATE ? (
                <TextField
                  value={history}
                  onChange={(e) => setHistory(e.target.value)}
                  multiline
                  rows={4}
                  fullWidth
                />
              ) : (
                <pre style={{ fontFamily: "inherit", whiteSpace: 'pre-wrap' }}>{history}</pre>
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ width: '120px' }}>設定状況</TableCell>
            <TableCell>
              {mode === InputMode.UPDATE ? (
                <FormControl component="fieldset">
                  <RadioGroup
                    row
                    aria-label="settingState"
                    name="settingState"
                    value={setting_state.toString()}
                    onChange={(e) => setSettingState(e.target.value === 'true')}
                  >
                    <FormControlLabel value="true" control={<Radio />} label="設定済み" />
                    <FormControlLabel value="false" control={<Radio />} label="未設定" />
                  </RadioGroup>
                </FormControl>
              ) : (
                <Typography>{setting_state ? "設定済み" : "未設定"}</Typography>
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ width: '120px' }}>更新日</TableCell>
            <TableCell>
              <Typography>{updated_at ? updated_at.toLocaleDateString() : '未設定'}</Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ width: '120px' }}>登録日</TableCell>
            <TableCell>
              <Typography>{created_at ? created_at.toLocaleDateString() : '未設定'}</Typography>
            </TableCell>
          </TableRow>

        </TableBody>
      </Table>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        message="情報が更新されました"
      />
    </Box >
  );
};

////ポイント
interface CustomerPointProps {
  customerData: Customer | null;
  updateCustomerData: () => Promise<void>;
}

const CustomerPoint: React.FC<CustomerPointProps> = ({ customerData, updateCustomerData }) => {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const rowsPerPage = 10;
  const [page, setPage] = useState(1);
  const [pointHistory, setPointHistory] = useState<any[]>([]);
  const [ruiseki_point, setRuisekiPoint] = useState(0);
  const [hoyu_point, setHoyuPoint] = useState(0);
  // 新規登録用のステート
  const [error, setError] = useState({
    newDate: '',
    pointContent: '',
    newPoint: '',
  });
  const [rankName, setRankName] = useState('');
  const komuten = useRecoilValue(komutenState);

  // ポイント履歴をフェッチしてステートを更新する関数
  const fetchPointHistory = useCallback(async () => {
    const pointHistoryData = await getPointHistory(customerData?.id || 0);
    if (pointHistoryData !== null) {
      let runningTotal = 0;
      const calculatedPointHistory = pointHistoryData.map((item: { change_point: number; }) => {
        runningTotal += item.change_point;
        return { ...item, zandaka: runningTotal };
      });
      setPointHistory(calculatedPointHistory.reverse());
    }
  }, [customerData?.id]);

  // 現在のページに応じて表示する履歴を計算
  const currentPointHistory = pointHistory.slice((page - 1) * rowsPerPage, page * rowsPerPage);


  useEffect(() => {
    fetchPointHistory();
  }, [fetchPointHistory]);

  useEffect(() => {
    if (customerData) {
      setRuisekiPoint(customerData.ruiseki_point || 0);
      //ランク取得
      const currentRank = komuten?.point_rank?.slice().reverse().find(rank => customerData.ruiseki_point >= rank.points);
      setRankName(currentRank?.rank || '');

      setHoyuPoint(customerData.hoyu_point || 0);
    }
  }, [customerData, komuten?.point_rank]);

  //新規登録
  const [open, setOpen] = useState(false);
  const [newPoint, setNewPoint] = useState('');
  const [pointContent, setPointContent] = useState('');
  const [newDate, setNewDate] = useState('');

  useEffect(() => {
    // 日本時間での現在日付をYYYY-MM-DD形式で取得
    const today = new Date().toLocaleString("ja-JP", {
      timeZone: "Asia/Tokyo",
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    }).split('/').join('-'); // フォーマットの調整

    setNewDate(today);
  }, []);

  // 新規登録
  const handleSubmit = async () => {
    if (!validateForm()) {
      return;
    }

    const changePointValue = parseInt(newPoint, 10);
    const changedAt = new Date(newDate).toISOString();
    try {
      await insertPointHistoryAndUpdateCustomer(customerData?.id || 0, pointContent, changePointValue, changedAt);
      updateCustomerData();
      fetchPointHistory();
      setOpenSnackbar(true);
    } catch (error) {
      console.error('Error:', error);
      alert('処理に失敗しました。');
    }
    handleClose();
  };


  const validateForm = () => {
    let isValid = true;
    const newError = {
      newDate: '',
      pointContent: '',
      newPoint: '',
    };

    if (!newDate) {
      newError.newDate = '変動日を入力してください。';
      isValid = false;
    }
    if (!pointContent.trim()) {
      newError.pointContent = 'ポイント内容を入力してください。';
      isValid = false;
    }
    if (!newPoint.trim() || isNaN(Number(newPoint))) {
      newError.newPoint = '有効なポイント増減値を入力してください。';
      isValid = false;
    }

    setError(newError);
    return isValid;
  };

  //削除
  const handleDelete = async (pointHistoryId: number) => {
    try {
      await deletePointHistoryAndUpdateCustomer(pointHistoryId);
      updateCustomerData();
      fetchPointHistory();
      setOpenSnackbar(true);
    } catch (error) {
      console.error('Error:', error);
      alert('削除処理に失敗しました。');
    }
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Typography variant="h6">現在のポイント：{hoyu_point}pt</Typography>
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Typography variant="h6">累積ポイント：{ruiseki_point}pt</Typography>
        <Typography variant="h6">ランク：{rankName}</Typography>
        <Box sx={{ display: "flex", justifyContent: "flex-end", marginBottom: 1 }}>
          <Button variant="outlined" onClick={handleOpen}>追加</Button>
        </Box>
        <Dialog open={open} onClose={handleClose} fullWidth>
          <DialogTitle>ポイント作成</DialogTitle>
          <DialogContent>
            <DialogContentText>ポイント内容を入力してください</DialogContentText>
            <FormControl fullWidth margin="normal" variant="outlined" sx={{ mt: 1 }}>
              <TextField
                error={!!error.newDate}
                helperText={error.newDate}
                label="変動日"
                type="date"
                value={newDate}
                onChange={(e) => setNewDate(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                margin="dense"
              />
            </FormControl>
            <FormControl fullWidth margin="normal" variant="outlined" sx={{ mt: 1 }}>
              <TextField
                error={!!error.pointContent}
                helperText={error.pointContent}
                label="ポイント内容"
                value={pointContent}
                onChange={(e) => setPointContent(e.target.value)}
                multiline
                rows={3}
                margin="dense"
              />
            </FormControl>
            <FormControl fullWidth margin="normal" variant="outlined" sx={{ mt: 1 }}>
              <TextField
                error={!!error.newPoint}
                helperText={error.newPoint}
                label="ポイント増減"
                value={newPoint}
                onChange={(e) => setNewPoint(e.target.value)}
                type="number"
                margin="dense"
              />
            </FormControl>
          </DialogContent>
          <DialogActions sx={{ flexDirection: 'column', alignItems: 'flex-start', padding: (theme) => theme.spacing(2) }}>
            <Button onClick={handleSubmit} variant="contained" sx={{ width: '100%', mb: 2 }}>作成</Button>
            <Button onClick={handleClose} sx={{ width: '100%' }}>キャンセル</Button>
          </DialogActions>
        </Dialog>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ whiteSpace: "nowrap" }}>変動日</TableCell>
              <TableCell sx={{ whiteSpace: "nowrap" }}>ポイント内容</TableCell>
              <TableCell sx={{ whiteSpace: "nowrap" }}>ポイント増減</TableCell>
              <TableCell sx={{ whiteSpace: "nowrap" }}>ポイント残高</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentPointHistory.length > 0 ? (
              currentPointHistory.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>
                    {new Date(row.changed_at).toLocaleDateString()}
                  </TableCell>
                  <TableCell>{row.content}</TableCell>
                  <TableCell>{`${row.change_point}pt`}</TableCell>
                  <TableCell>{`${row.zandaka}pt`}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleDelete(row.id)}>
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={5} style={{ textAlign: 'center' }}>未登録</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            mt: 2,
            mb: 2
          }}
        >
          <Pagination
            // count={Math.ceil(totalRecords / rowsPerPage)}
            // page={page}
            // onChange={handlePageChange}
            count={Math.ceil(pointHistory.length / rowsPerPage)}
            page={page}
            onChange={(event, newPage) => setPage(newPage)}
            color="primary"
            showFirstButton
            showLastButton
          />
        </Box>
      </TableContainer>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        message="情報が更新されました"
      />
    </>
  );
};

// 書類の型定義
interface Document {
  syorui_name: string;
  file_name: string;
  file_path: string;
  created_at: string;
}

interface DocumentListProps {
  customerData: Customer | null;
}

const DocumentList: React.FC<DocumentListProps> = ({ customerData }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [documents, setDocuments] = useState<Document[]>([]);
  const [newSyoruiName, setNewSyoruiName] = useState<string>('');
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const documentsPerPage = 10;
  const [totalRecords, setTotalRecords] = useState(0);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [page, setPage] = useState(1);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [displayDocuments, setDisplayDocuments] = useState<Document[]>([]);
  const komuten = useRecoilValue(komutenState);
  const [storageUsage, setStorageUsage] = useState(0);



  //書類情報取得
  useEffect(() => {
    const init = async () => {
      try {
        const customerId = customerData?.id ?? 0;
        const documentsData = await fetchCustomerDocuments(customerId);
        if (documentsData.length === 0) {
          // ドキュメントデータが空の場合、以降の処理を行わない
          return;
        }
        setTotalRecords(documentsData.length); // 総レコード数を更新
        const documentsWithUrl = await Promise.all(documentsData.map(async (doc: { file_path: string; }) => {
          try {
            const url = await getFileUrl('syorui', doc.file_path);
            return { ...doc, url };
          } catch (error) {
            console.error('Error fetching file URL:', error);
            return { ...doc, url: '', error: 'Failed to load document URL' };
          }
        }));
        setDocuments(documentsWithUrl);
      } catch (error) {
        console.error('Error initializing documents:', error);
      }
    };
    init();
  }, [customerData?.id]);

  const handleSubmit = async () => {
    if (!selectedFile || !newSyoruiName) {
      alert("ファイルまたは書類名が指定されていません。");
      return;
    }
    if (storageUsage + selectedFile.size >= komuten!.storage_size) {
      alert("ストレージ使用量が契約ストレージ使用量を超過するため登録できません。");
      return;
    }
    let uploadPath = null;
    try {

      const filePath = `${komuten!.id}/${customerData?.id}_${Date.now()}`;
      uploadPath = await uploadFile('syorui', filePath, selectedFile);

      await insertSyoruiMetadata(
        customerData?.id ?? 0,
        uploadPath.Key,
        newSyoruiName,
        selectedFile.name,
        komuten!.id,
        selectedFile.size
      );

      // ここで新しいドキュメントをdocuments配列に追加
      const newDocument = {
        syorui_name: newSyoruiName,
        file_name: selectedFile.name,
        file_path: uploadPath.Key,
        created_at: new Date().toISOString(),
      };

      // 既存のdocuments配列と新しいドキュメントを結合してcreated_atで降順に並び替え
      setDocuments(prevDocuments =>
        [...prevDocuments, newDocument].sort((a, b) =>
          new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
        )
      );

      setOpen(false);
      setOpenSnackbar(true);
    } catch (error) {
      console.error('Error:', error);
      alert('処理に失敗しました。');
      if (uploadPath && uploadPath.Key) {
        try {
          await removeFile('syorui', uploadPath.Key);
        } catch (removeError) {
          console.error('Failed to remove uploaded file:', removeError);
        }
      }
    }
  };


  const storageCalculation = async (komuten_id: number) => {
    try {
      const data = await fetchKomutenDocuments(komuten_id);
      if (data) {
        const totalSize = data.reduce((acc: number, doc: { file_size: number; }) => acc + doc.file_size, 0);
        setStorageUsage(totalSize);
      };
    } catch (error) {
      console.error('Error fetchKomutenDocuments:', error);
      alert('データの読み込みに失敗しました。もう一度お試しください。');
    }
  };


  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (file && file.size <= 10 * 1000 * 1000 && file.type === "application/pdf") {
      setSelectedFile(file);
      setNewSyoruiName(file.name);
      await storageCalculation(komuten!.id);

    } else {
      alert("ファイルはPDF形式で、10MB以下である必要があります。");
      setSelectedFile(null);
      setNewSyoruiName('');
    }
  };


  const handleDelete = async (index: number) => {
    const document = documents[index];
    if (!document || !document.file_path) {
      alert("削除するファイルが見つかりません。");
      return;
    }

    try {
      // 別ファイルで定義された関数を呼び出してファイルメタデータを削除
      await deleteDocumentMetadata(document.file_path);

      // Storageからファイルを削除
      await removeFile('syorui', document.file_path);

      // フロントエンドの状態を更新
      const updatedDocuments = documents.filter((_, docIndex) => docIndex !== index);
      setDocuments(updatedDocuments);
      setOpenSnackbar(true);
    } catch (error) {
      console.error('Error:', error);
      alert("ファイルの削除に失敗しました。");
    }
  };

  //ダウンロード
  const handleDownloadClick = async (filePath: string) => {
    const publicUrl = await getFilePublicUrl('syorui', filePath);
    if (publicUrl) {
      window.open(publicUrl, '_blank');
    }
  };

  useEffect(() => {
    // ページ番号やドキュメントが変更された時に実行される
    const updatedDisplayDocuments = documents.slice(
      (page - 1) * documentsPerPage,
      page * documentsPerPage
    );
    setDisplayDocuments(updatedDisplayDocuments); // Stateを更新して表示を変更
  }, [page, documents]); // 依存配列にpageとdocumentsを追加

  return (
    <Box padding={3}>
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
        marginBottom={3}
      >
        <Typography variant="h5">書類</Typography>
        <Button variant="outlined" onClick={handleOpen}>追加</Button>
        <Dialog open={open} onClose={handleClose} fullWidth BackdropProps={{
          style: {
            opacity: 0.3, // ここで背景の暗さを調整
          }
        }}>
          <DialogTitle>書類登録</DialogTitle>
          <DialogContent>
            <Dialog open={open} onClose={handleClose} fullWidth>
              <DialogTitle>書類登録</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  登録するファイルを選択してください。（PDF形式のみ、10MB以下）
                </DialogContentText>
                <FormControl fullWidth margin="normal">
                  <InputLabel htmlFor="document-upload"></InputLabel>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mt: 2, mb: 1 }}>
                    <Button
                      variant="contained"
                      component="label"
                      htmlFor="document-upload"
                    >
                      ファイルを選択
                    </Button>
                    <Typography variant="body2" noWrap>
                      {selectedFile ? selectedFile.name : 'ファイルが選択されていません'}
                    </Typography>
                    <Input
                      id="document-upload"
                      type="file"
                      inputProps={{
                        accept: ".pdf", // PDF形式のみを許可
                      }}
                      onChange={handleFileChange}
                      sx={{ display: 'none' }}
                    />
                  </Box>
                </FormControl>
                <FormControl fullWidth margin="normal" variant="outlined" sx={{ mt: 1 }}>
                  <TextField
                    label="書類名"
                    value={newSyoruiName}
                    onChange={(e) => setNewSyoruiName(e.target.value)}
                    margin="dense"
                  />
                </FormControl>
              </DialogContent>
              <DialogActions sx={{ flexDirection: 'column', alignItems: 'flex-start', padding: (theme) => theme.spacing(2) }}>
                <Button onClick={handleSubmit} variant="contained" sx={{ width: '100%', mb: 2 }}>登録</Button>
                <Button onClick={handleClose} sx={{ width: '100%' }}>キャンセル</Button>
              </DialogActions>
            </Dialog>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleSubmit} variant="contained">登録</Button>
            <Button onClick={handleClose}>キャンセル</Button>
          </DialogActions>
        </Dialog>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ whiteSpace: "nowrap" }}>書類名</TableCell>
              {/* <TableCell sx={{ whiteSpace: "nowrap" }}>ファイル名</TableCell> */}
              <TableCell sx={{ whiteSpace: "nowrap" }}>登録日</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {displayDocuments.length > 0 ? (
              displayDocuments.map((doc, index) => (
                <TableRow key={index}>
                  <TableCell>{doc.syorui_name}</TableCell>
                  {/* <TableCell>{doc.file_name}</TableCell> */}
                  <TableCell>
                    {new Date(doc.created_at).toLocaleDateString('ja-JP', {
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit'
                    })}
                  </TableCell>
                  <TableCell>
                    <IconButton
                      aria-label="download"
                      size="small"
                      sx={{ marginRight: 5 }}
                      onClick={() => handleDownloadClick(doc.file_path)}
                    >
                      <Download />
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleDelete(index)} aria-label="delete" size="small">
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={4} style={{ textAlign: 'center' }}>
                  登録されている書類はありません
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <Box
          position={"relative"}
          alignItems={"center"}
          justifyContent={"center"}
          display={"flex"}
          padding={2}
          sx={{ width: "100%" }}
        >
          <Pagination
            count={Math.ceil(totalRecords / documentsPerPage)}
            page={page}
            onChange={(event, newPage) => setPage(newPage)}
            color="primary"
            showFirstButton
            showLastButton
          />
        </Box>
      </TableContainer>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        message="情報が更新されました"
      />
    </Box>
  );
};


const CustomerDetail: FC = () => {
  const { customer_id } = useParams<{ customer_id?: string }>();
  const [customer, setCustomer] = useState<Customer | null>(null);
  const [selectedContent, setSelectedContent] = useState<string>("basicInfo");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [name, setName] = useState("");

  const fetchAndUpdateCustomer = async (customerId: number) => {
    const response = await getCustomer(customerId);
    // エラーチェックを追加
    if (response.error) {
      alert(`エラーが発生しました。画面の読み込みを再度行ってみてください。`);
      return;
    }

    if (response.data && response.data.length > 0) {
      setCustomer(response.data[0]);
    } else {
      alert('該当するオーナー情報が見つかりません。');
    }
  };

  useEffect(() => {
    const customerId = customer_id ? parseInt(customer_id, 10) : 0;
    fetchAndUpdateCustomer(customerId);
  }, [customer_id]);

  const updateCustomerData = async () => {
    const customerId = customer_id ? parseInt(customer_id, 10) : 0;
    fetchAndUpdateCustomer(customerId);
  };

  useEffect(() => {
    if (customer) {
      setName(customer.name || "");
    }
  }, [customer]); // customer ステートが更新されたときに実行


  const handleTabChange = (tabId: string) => {
    setSelectedContent(tabId);
    navigate(`${location.pathname}?tab=${tabId}`);
  };

  const handleDelete = () => {
    setOpenDialog(true);
  };

  const confirmDelete = async () => {
    if (!customer?.id) {
      console.error('Customer ID is undefined or 0, aborting delete operation.');
      setOpenSnackbar(true);
      return;
    }

    try {
      await deleteCustomer(customer.id);
      setOpenDialog(false);
      setOpenSnackbar(true);
      navigate(PATHS.CUSTOMER);
    } catch (error) {
      console.error('Failed to delete customer:', error);
      setOpenSnackbar(true);
    }
  };


  const listItems = [
    { id: "basicInfo", text: "基本情報", component: BasicInfo },
    { id: "CustomerPoint", text: "ポイント", component: CustomerPoint },
    { id: "documentList", text: "書類", component: DocumentList },
  ];

  return (
    <PageContainer>
      <Box>
        <Typography variant="h4" component="div" sx={{ flexGrow: 1, textAlign: "left", marginBottom: 1 }}>
          オーナー詳細
        </Typography>
        <Typography variant="h6" sx={{ color: "gray", paddingBottom: "20px" }}>
          {name}様
        </Typography>
      </Box>
      <Box display="flex" justifyContent="space-between" border={1} borderColor="#0000001A">
        <List sx={{ borderRight: 1, borderColor: "#0000001A", width: "180px", padding: 1 }}>
          {listItems.map((item) => (
            <SubMenuItem
              key={item.id}
              text={item.text}
              selected={selectedContent === item.id}
              onClick={() => handleTabChange(item.id)}
            />
          ))}
        </List>
        <Box flexGrow={1} display="flex" flexDirection="column" justifyContent="flex-start" rowGap={2} padding={2}>
          {selectedContent === "basicInfo" && <BasicInfo customerDafalutData={customer} />}
          {selectedContent === "CustomerPoint" &&
            <CustomerPoint customerData={customer} updateCustomerData={updateCustomerData} />}
          {selectedContent === "documentList" && <DocumentList customerData={customer} />}
        </Box>
      </Box>
      <Box display="flex" justifyContent="flex-end" marginTop={2}>
        <Button variant="contained" color="error" onClick={handleDelete}>
          削除
        </Button>
      </Box>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        message="削除が完了しました。"
      />
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle color="error">削除の確認</DialogTitle>
        <DialogContent>
          <Typography>本当に{name}様を削除してもよろしいですか？</Typography>
          <Typography>削除したデータを戻すことはできません。</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="primary">
            キャンセル
          </Button>
          <Button onClick={confirmDelete} color="error">
            削除する
          </Button>
        </DialogActions>
      </Dialog>
    </PageContainer>
  );
};

export default CustomerDetail;
