import { supabase } from "../utils/supabase";

//ポイント獲得方法の追加
export async function insertPointGet(_komuten_uid: string, _way: string, _give_point: string) {
  const { error, data } = await supabase
    .rpc('insert_point_get', {
      _komuten_uid: _komuten_uid,
      _way: _way,
      _give_point: _give_point
    });
  if (error) {
    console.error('Insert PointGet error:', error.message);
    throw new Error(error.message);
  }
  return data;
}

//削除
export const deletePointGetWay = async (pointGetId: number) => {
  try {
    const { error } = await supabase
      .rpc('delete_point_get_way', { point_get_id: pointGetId });

    if (error) throw error;

    return true; // 削除成功
  } catch (error) {
    console.error('Error deleting point get way:', error);
    return false; // 削除失敗
  }
};

//ポイント獲得方法の取得
export const getPointGetWay = async (komuten_uid: string, page: number, rowsPerPage: number) => {
  try {
    const { data, error } = await supabase
      .rpc("get_point_get_way", { _komuten_uid: komuten_uid, _page: page, _rows_per_page: rowsPerPage });

    if (error) {
      console.error('getPointGetWay error:', error.message);
      throw new Error(error.message);
    }

    return {
      data: data[0].data,
      totalCount: data[0].total_count,
    };
  } catch (error) {
    console.error("Error fetching point get way list:", error);
    return { data: [], totalCount: 0, error: error || "An error occurred while fetching the data." };
  }
};
