// import Dialog from "@mui/material/Dialog";
// import DialogActions from "@mui/material/DialogActions";
// import DialogContent from "@mui/material/DialogContent";
// import DialogContentText from "@mui/material/DialogContentText";
// import DialogTitle from "@mui/material/DialogTitle";
import { FC, useEffect, useState } from "react";

import { ContentPasteOutlined } from "@mui/icons-material";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Card,
  // IconButton,
  // Step,
  // StepLabel,
  // Stepper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  // TextField,
  Tooltip,
  Typography,
} from "@mui/material";
// import React from "react";
import { PRDetail } from "../../types/sakuzyoyotei/pr";
import DraftDetailStepper from "./draftDetailStepper";
import { useNavigate, useParams } from "react-router-dom";
import { PATHS } from "../../paths";
import { InputMode } from "../../types/inputMode";
import { convertDateFormat } from "../../utils/datetimeUtils";

export type DraftDetailCreateProps = {
  detail: PRDetail | undefined;
  handleTabChange: (tabId: string) => void;
};

const DraftDetailPreview: FC<DraftDetailCreateProps> = (props) => {
  const [openTip, setOpenTip] = useState<boolean>(false);
  const navigate = useNavigate();
  const { id } = useParams();

  const handleCloseTip = (): void => {
    setOpenTip(false);
  };

  const handleClickButton = (): void => {
    setOpenTip(true);

    var text = "";

    text += props.detail?.data.last.title + "\n\n";
    text += props.detail?.data.last.summary + "\n\n";
    props.detail?.data.last.details &&
      props.detail?.data.last.table_of_contents &&
      Object.keys(props.detail?.data.last.details).forEach((key) => {
        text += props.detail?.data.last.table_of_contents[key] + "\n";
        text += props.detail?.data.last.details[key] + "\n\n";
      });

    navigator.clipboard.writeText(text);

    setTimeout(() => setOpenTip(false), 1000);
  };

  useEffect(() => {
    return () => { };
  }, []);

  const sections = Object.keys(props.detail?.data.last.details || {});

  return (
    <Box
      flexGrow={1}
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"flex-start"}
      alignItems={"flex-start"}
      rowGap={2}
      padding={2}
    >
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        width={"100%"}
      >
        <Typography variant="h5">プレビュー</Typography>
        <Box
          display={"flex"}
          justifyContent={"flex-end"}
          alignItems={"center"}
          columnGap={1}
        >
          <Button
            variant="text"
            onClick={() => {
              props.handleTabChange("first");
            }}
          >
            内容を修正
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              props.handleTabChange("create");
              navigate(
                `${PATHS.DRAFT}/${InputMode.COPY}?mode=${InputMode.COPY}&tab=create&copy_id=${id}`
              );
            }}
          >
            コピーして作成
          </Button>
          <Tooltip
            arrow
            open={openTip}
            onClose={handleCloseTip}
            disableHoverListener
            placement="top"
            title="クリップボードにコピーしました"
          >
            <Button
              startIcon={<ContentPasteOutlined />}
              variant="contained"
              onClick={() => handleClickButton()}
            >
              全文書をコピー
            </Button>
          </Tooltip>
        </Box>
      </Box>
      <Alert severity="success" sx={{ width: "100%" }}>
        <AlertTitle>プレスリリースの作成を完了しました</AlertTitle>
        {convertDateFormat(props.detail?.release_time)}
      </Alert>
      <DraftDetailStepper activeStep={2} />
      <Card sx={{ width: "100%", padding: 2 }}>
        <Box width="100%" display={"flex"} flexDirection={"column"} rowGap={2}>
          <Typography variant="h6" fontWeight={"bold"}>
            {props.detail?.data.last.title}
          </Typography>
          <Typography whiteSpace={"pre-wrap"}>
            {props.detail?.data.last.summary}
          </Typography>
          {sections.map((sectionName, index) => (
            <Box display={"flex"} flexDirection={"column"} rowGap={2}>
              <Typography whiteSpace={"nowrap"} fontWeight={"bold"}>
                {
                  props.detail?.data.last.table_of_contents[
                  sectionName as keyof typeof props.detail
                  ]
                }
              </Typography>
              <Typography whiteSpace={"pre-wrap"}>
                {
                  props.detail?.data.last.details[
                  sectionName as keyof typeof props.detail
                  ]
                }
              </Typography>
            </Box>
          ))}
        </Box>
      </Card>
      <Typography variant="h6">プレスリリースカテゴリー</Typography>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell width={"170px"}>
              <Typography variant="body2" whiteSpace={"nowrap"}>
                カテゴリー
              </Typography>
            </TableCell>
            <TableCell>
              <Typography whiteSpace={"pre-wrap"}>
                {props.detail?.data.request.category === 1
                  ? "商品サービス"
                  : props.detail?.data.request.category === 2
                    ? "イベント"
                    : props.detail?.data.request.category === 3
                      ? "キャンペーン"
                      : props.detail?.data.request.category === 4
                        ? "調査"
                        : props.detail?.data.request.category === 5
                          ? "IR（業務提携）"
                          : props.detail?.data.request.category === 6
                            ? "IR（人事）"
                            : ""}
              </Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  );
};
export default DraftDetailPreview;
