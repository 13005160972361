import React, { ChangeEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
// import { myRoleState } from "../recoil/sideBarState";
import {
  Box,
  Button,
  Card,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  Alert,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import AuthContainer from "../components/authContainer";
import { login } from "../api/komuten";
import { komutenState } from "../recoil/komutenState";
import { useRecoilState } from 'recoil';
import { authState } from "../recoil/authState";


const AuthSignIn: React.FC = () => {
  const navigate = useNavigate();
  const [accountId, setAccountId] = useState("");
  const [password, setPassword] = useState("");
  const [isError, setIsError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  // const setMyRole = useSetRecoilState(myRoleState);
  const setAuth = useSetRecoilState(authState);
  const [komuten, setKomuten] = useRecoilState(komutenState);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setAccountId(event.target.value);
  };

  //ログイン時処理
  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const { error, data, exists } = await login(accountId, password);
    if (exists) {
      setKomuten(data);
      localStorage.setItem('komutenData', JSON.stringify(data));
      setAuth({ isLoading: false, isAuthenticated: true }); // 認証状態を更新
      setIsError(false);
      // setMyRole(data); // ロールを設定
      navigate("/customer"); // 成功時のリダイレクト
    } else {
      console.log('エラー発生。komuten:', komuten);
      setAuth({ isLoading: false, isAuthenticated: false }); // 認証状態を更新
      setIsError(true); // エラーフラグをtrueに
      console.error(error);
      // alert(error || "ログインに失敗しました。");
    }
  };

  return (
    <AuthContainer>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          alignContent: "center",
          height: "85vh",
        }}
      >
        <Card
          elevation={3}
          sx={{ margin: "auto", display: "flex", flexDirection: "column" }}
        >
          <Box
            width={"540px"}
            paddingInline={2}
            paddingBlock={4}
            display={"flex"}
            flexDirection={"column"}
            gap={2}
          >
            <Typography variant="h5" paddingBlock={2}>
              ログイン
            </Typography>
            {isError && (
              <Alert severity="error">
                ログインIDまたはパスワードが間違っています。
              </Alert>
            )}
            <form onSubmit={handleSubmit}>
              <Box display={"flex"} flexDirection={"column"} gap={2}>
                <TextField
                  fullWidth
                  value={accountId}
                  onChange={handleEmailChange}
                  label="ログインID"
                />
                <TextField
                  fullWidth
                  type={showPassword ? "text" : "password"}
                  onChange={(e) => setPassword(e.target.value)}
                  label="パスワード"
                  value={password}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={togglePasswordVisibility} edge="end">
                          {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  sx={{ height: "42px" }}
                >
                  ログイン
                </Button>
              </Box>
            </form>
          </Box>
        </Card>
      </Box>
    </AuthContainer>
  );
};

export default AuthSignIn;
