import { supabase } from "../utils/supabase";

export async function getPointHistory(customerTableId: number) {
    let { data, error } = await supabase
        .rpc('get_point_history', { _customer_table_id: customerTableId });

    if (error) {
        console.error('getPointHistory error:', error.message);
        throw new Error(error.message);
    }
    return data;
}