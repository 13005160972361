import AppBar, { AppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar/Toolbar";
import { FC } from "react";
import { Box } from "@mui/material";
import React from "react";
import logoIcon from "../assets/logo.png";

export type AuthHeaderProps = {} & AppBarProps;

const AuthHeader: FC<AuthHeaderProps> = (props) => {
  return (
    <AppBar
      // color="transparent"
      position="static"
      elevation={0}
      sx={{
        borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
        backgroundColor: "white",
      }}
    >
      <Toolbar>
        <Box textAlign="left" sx={{ flexGrow: 1 }}>
          <img src={logoIcon} alt="" style={{ height: '35px', width: 'auto' }} />
        </Box>
        {/* <Tooltip title="使い方を確認する">
          <IconButton sx={{ color: "white" }} onClick={handleOpenUserMenu}>
            <HelpOutlineOutlinedIcon />
          </IconButton>
        </Tooltip> */}
      </Toolbar>
    </AppBar>
  );
};
export default AuthHeader;
