import { supabase } from "../utils/supabase";

//工務店の追加
export async function insertKomuten(_account_id: string, _password: string) {
  const { error, data } = await supabase
    .rpc('insert_komuten', {
      _account_id: _account_id,
      _password: _password,
    });
  if (error) {
    console.error('Insert Komuten error:', error.message);
    throw new Error(error.message);
  }
  return data;
}

export async function login(accountId: string, password: string) {
  try {
    // まずはアカウントIDでユーザーを検索
    const { data, error } = await supabase
      .from('komuten')
      .select('*')
      .eq('account_id', accountId)
      .single();

    if (error) throw new Error('エラーが発生しました。再度お試しください。');
    if (!data) throw new Error('アカウントが見つかりません。');

    //Supabase Functionを通じてパスワードの検証(ハッシュ化)を行う
    const { error: verifyError, data: verifyData } = await supabase
      .rpc('verify_password', { input_password: password, stored_password: data.password });

    if (verifyError) throw new Error('パスワードの検証に失敗しました。');
    if (!verifyData) throw new Error('パスワードが一致しません。');

    // ログイン成功
    return { exists: true, data: data };
  } catch (error: unknown) {
    if (error instanceof Error) {
      console.error('エラー発生:', error.message);
      return { exists: false, error: error.message };
    } else {
      console.error('エラーが発生しました。');
      return { exists: false, error: 'エラーが発生しました。' };
    }
  }
}

//工務店基本情報
export const updateKomutenInfo = async (komutenData: { id: any; name: any; name_kana: any; email: any; tell_number: any; point_rate: any; point_rank: any }) => {
  const { error } = await supabase
    .rpc('update_komuten_info', {
      _id: komutenData.id,
      _name: komutenData.name,
      _name_kana: komutenData.name_kana,
      _email: komutenData.email,
      _tell_number: komutenData.tell_number,
      _point_rate: komutenData.point_rate,
      _point_rank: komutenData.point_rank
    });

  if (error) {
    console.error('Error updating komuten info:', error.message);
    throw new Error('更新に失敗しました。もう一度お試しください。');
  }
};

// 工務店のパスワード更新
export async function updateKomutenPassword(_account_id: string, _new_password: string) {
  const { error, data } = await supabase
    .rpc('update_komuten_password', {
      _account_id: _account_id,
      _new_password: _new_password,
    });
  if (error) {
    console.error('Update Komuten Password error:', error.message);
    throw new Error(error.message);
  }
  return data;
}
