import { supabase } from "../utils/supabase";

// export const getAllKomutenCustomer = async (komuten_uid: string, page: number, rowsPerPage: number, searchQuery: string) => {
//   try {
//     const startIndex = (page - 1) * rowsPerPage;
//     const endIndex = startIndex + rowsPerPage - 1;

//     let queryBuilder = supabase
//       .from("customer")
//       .select("*", { count: "exact" })
//       .eq("komuten_uid", komuten_uid)
//       .order("created_at", { ascending: false });

//     if (searchQuery) {
//       // 検索クエリを複数のカラムに適用
//       const orCondition = `name.ilike.%${searchQuery}%,zyusyo.ilike.%${searchQuery}%,kubun.ilike.%${searchQuery}%,tantosya.ilike.%${searchQuery}%`;
//       queryBuilder = queryBuilder.or(orCondition);
//     }

//     const response = await queryBuilder.range(startIndex, endIndex);

//     if (response.error) {
//       return { data: [], totalCount: 0, error: response.error };
//     }

//     return {
//       data: response.data,
//       totalCount: response.count,
//       error: null
//     };
//   } catch (error) {
//     console.error("Error fetching customer data:", error);
//     return { data: [], totalCount: 0, error: error || "An error occurred while fetching customer data." };
//   }
// };


export const getAllKomutenCustomer = async (
  komuten_uid: string,
  page: number,
  rowsPerPage: number,
  searchQuery: string,
  sortBy: string = 'created_at', // デフォルトのソート対象カラム
  sortOrder: 'asc' | 'desc' = 'asc' // デフォルトのソート順
) => {
  try {
    const startIndex = (page - 1) * rowsPerPage;
    const endIndex = startIndex + rowsPerPage - 1;

    let queryBuilder = supabase
      .from("customer")
      .select("*", { count: "exact" })
      .eq("komuten_uid", komuten_uid)
      .order(sortBy, { ascending: sortOrder === 'asc' }) // ソート順を適用
      ;

    if (searchQuery) {
      // 検索クエリを複数のカラムに適用
      const orCondition = `name.ilike.%${searchQuery}%,nick_name.ilike.%${searchQuery}%,zyusyo.ilike.%${searchQuery}%,kubun.ilike.%${searchQuery}%,tantosya.ilike.%${searchQuery}%`;
      queryBuilder = queryBuilder.or(orCondition);
    }

    const response = await queryBuilder.range(startIndex, endIndex);

    if (response.error) {
      throw new Error(response.error.message);
    }

    return {
      data: response.data,
      totalCount: response.count,
      error: null
    };
  } catch (error) {
    console.error("Error fetching customer data:", error);
    throw error; // エラーをスローして、呼び出し側でキャッチできるようにする
  }
};
