export function convertDateFormat(inputDate: string | number | Date | undefined) {
  if (!inputDate) {
    return ""; // nullの場合は空文字列を返す
  }

  const date = new Date(inputDate);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hours = String(date.getHours()).padStart(2, '0'); // 2桁表示に変換
  const minutes = String(date.getMinutes()).padStart(2, '0'); // 2桁表示に変換

  const formattedDate = `${year}年${month}月${day}日 ${hours}:${minutes}`;
  return formattedDate;
}
