import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@mui/material";
import { FC, useEffect, useRef, useState, useCallback } from "react";
import ContactPhoneIcon from "@mui/icons-material/ContactPhoneOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import logoIcon from "../assets/logo.png";
import logoSmallIcon from "../assets/logo_small.png";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import LaunchOutlinedIcon from "@mui/icons-material/LaunchOutlined";
import { useLocation, useNavigate } from "react-router-dom";
import { LogoutOutlined } from "@mui/icons-material";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import openCenteredPopup from "../utils/urlUtil";
import {
  isSideBarOpenState,
  myRoleState,
  sidebarScrollPositionState,
} from "../recoil/sideBarState";
import { MenuData } from "../types/menu";
import { getMenu } from "../api/menus";
import { komutenState } from "../recoil/komutenState";
import { authState } from "../recoil/authState";

export interface SidebarProps { }

const Sidebar: FC<SidebarProps> = () => {
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useRecoilState(isSideBarOpenState);
  const [scrollPosition, setScrollPosition] = useRecoilState(
    sidebarScrollPositionState
  );
  const sidebarRef = useRef<HTMLDivElement>(null);
  const [menus, setMenus] = useState<MenuData>({ data: [] }); // Stateの追加
  const menuWidth = isMenuOpen ? "210px" : "90px";
  const role = useRecoilValue(myRoleState);
  const setKomuten = useSetRecoilState(komutenState);
  const setAuth = useSetRecoilState(authState);

  const handleScroll = () => {
    if (sidebarRef.current) {
      setScrollPosition(sidebarRef.current.scrollTop);
    }
  };

  const navigate = useNavigate();

  // ログアウト処理を実行し、成功したらログインページに遷移する関数
  const handleLogout = async () => {
    // Recoilのユーザーステートをクリア
    setKomuten(null);
    setAuth({ isLoading: false, isAuthenticated: false });

    // localStorageからユーザーデータを削除
    localStorage.removeItem('komutenData');

    navigate('/signin'); // ログイン画面のパスに遷移
  };

  const fetchMenu = useCallback(async () => {
    try {
      const data = await getMenu(role);
      setMenus(data);
    } catch (error) {
      console.error("Failed to fetch menu:", error);
    }
  }, [role]);

  useEffect(() => {
    if (menus.data.length === 0) {
      fetchMenu();
    }

    if (sidebarRef.current) {
      sidebarRef.current.scrollTop = scrollPosition;
    }
  }, [menus.data.length, scrollPosition, fetchMenu]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const openLinkInNewWindow = (url: string | URL | undefined) => {
    openCenteredPopup(url, 1200, 600);
  };

  const getIcon = (icon: string) => {
    switch (icon) {
      case "<SettingsOutlinedIcon />":
        return <SettingsOutlinedIcon />;
      case "<GroupsOutlinedIcon />":
        return <GroupsOutlinedIcon />;
      case "<LogoutOutlined />":
        return <LogoutOutlined />;
      default:
        return <ContactPhoneIcon />;
    }
  };

  const handleMenuClick = async (menu: any) => {
    if (menu.icon === "<LogoutOutlined />") {
      try {
        await handleLogout(); // ログアウト処理を呼び出し
      } catch (error) {
        alert('ログアウトに失敗しました。もう一度お試しください。'); // エラーメッセージを表示
      }
    } else if (menu.popup) {
      openLinkInNewWindow(menu.url);
    } else {
      navigate(menu.url); // 通常のページ遷移
    }
  };

  const drawer = (
    <Box
      key={"sidebar"}
      ref={sidebarRef}
      onScroll={handleScroll}
      width="100%"
      padding="0 8px 0 8px"
      sx={{
        maxHeight: "100vh",
        overflowY: "auto",
      }}
    >
      <Box
        textAlign="center"
        flexGrow={1}
        alignItems="center"
        alignContent="center"
        justifyContent="center"
        display="flex"
        height="64px"
      >
        <style>
          {`
      img.logo {
          width: 100%;
          max-width: 165px;
          padding: 15px;
       }

      img.logoSmall {
          width: 100%;
          max-width: 32px;
          padding: 15px 0;
      }
    `}
        </style>
        {isMenuOpen ? (
          <>
            <img className="logo" src={logoIcon} alt="" />
          </>
        ) : (
          <>
            <img className="logoSmall" src={logoSmallIcon} alt="" />
          </>
        )}
      </Box>
      {menus.data.map((group, groupIndex) => {
        return (
          <Box key={groupIndex}>
            <List>
              {group.menu.map((menu, menuIndex) => {
                const isSelected = location.pathname.startsWith(menu.url);
                return (
                  <ListItem key={menuIndex} disablePadding>
                    <Tooltip
                      title={isMenuOpen ? "" : menu.title}
                      placement="right"
                    >
                      <ListItemButton
                        onClick={() => handleMenuClick(menu)}
                        sx={{
                          borderRadius: "4px",
                          width: "100%",
                          justifyContent: isMenuOpen ? null : "center",
                          padding: isMenuOpen ? null : "8px 0",
                          backgroundColor: isSelected
                            ? "#5B98F414"
                            : "inherit",
                          "&:hover": {
                            backgroundColor: "#5B98F414",
                          },
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            color: isSelected ? "primary.main" : "inherit",
                            minWidth: isMenuOpen ? null : 0,
                          }}
                        >
                          {getIcon(menu.icon)}
                        </ListItemIcon>
                        <ListItemText
                          primary={isMenuOpen ? menu.title : ""}
                          sx={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            flexGrow: isMenuOpen ? 1 : 0,
                            opacity: isMenuOpen ? 1 : 0,
                            visibility: isMenuOpen ? "visible" : "hidden",
                            transition: isMenuOpen
                              ? "max-width 0.3s ease 0.3s, opacity 0.2s ease 0.3s, visibility 0s ease 0.3s"
                              : "max-width 0.3s ease, opacity 0.2s ease, visibility 0s ease 0.3s",
                          }}
                        />
                        {isMenuOpen && menu.popup && <LaunchOutlinedIcon />}
                      </ListItemButton>
                    </Tooltip>
                  </ListItem>
                );
              })}
            </List>
          </Box>
        );
      })}
    </Box>
  );

  return (
    <Box
      component="nav"
      display="grid"
      flexShrink={0}
      flexGrow={0}
      width={menuWidth}
      borderRight={1}
      borderColor={"#E5E5E5"}
      sx={{
        transition: "width 0.3s ease", // 幅の変更にアニメーションを追加
        boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.1)", // ホバー時の影を小さくする
      }}
    >
      {drawer}
      <Box
        justifyContent="space-between"
        display="flex"
        flexDirection="column"
        height="100%"
        paddingBottom={3}
      >
        <Box />
        {isMenuOpen ? (
          <Box alignSelf="flex-end">
            <IconButton onClick={toggleMenu}>
              <KeyboardDoubleArrowLeftIcon />
            </IconButton>
          </Box>
        ) : (
          <Box alignSelf="center">
            <IconButton onClick={toggleMenu}>
              <KeyboardDoubleArrowRightIcon />
            </IconButton>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Sidebar;
