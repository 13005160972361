import { supabase } from "../utils/supabase";

export const insertSyoruiMetadata = async (customer_id: number, file_path: string, syorui_name: string, file_name: string, komuten_id: number, file_size: number,) => {
  const { data, error } = await supabase
    .rpc('insert_syorui_metadata', {
      _customer_id: customer_id,
      _file_path: file_path,
      _syorui_name: syorui_name,
      _file_name: file_name,
      _komuten_id: komuten_id,
      _file_size: file_size,
    });

  if (error) throw new Error(error.message);

  return data;
};

export const fetchCustomerDocuments = async (customerId: number) => {
  try {
    const { data, error } = await supabase
      .rpc('fetch_documents_for_customer', { _customer_id: customerId });

    if (error) {
      console.error('Error fetching documents:', error);
      return []; // エラーがあった場合は空の配列を返す
    }
    return data || []; // dataがundefinedの場合は空の配列を返す
  } catch (error) {
    console.error('Fetch documents error:', error);
    return []; // 例外が発生した場合も空の配列を返す
  }
};

export const fetchKomutenDocuments = async (komutenId: number) => {
  try {
    const { data, error } = await supabase
      .rpc('fetch_documents_for_komuten', { _komuten_id: komutenId });
    if (error) {
      console.error('Error komuten fetching documents:', error);
      return []; // エラーがあった場合は空の配列を返す
    }
    return data || []; // dataがundefinedの場合は空の配列を返す
  } catch (error) {
    console.error('Fetch komuten documents error:', error);
    return []; // 例外が発生した場合も空の配列を返す
  }
};


// ファイルメタデータを削除する関数
export const deleteDocumentMetadata = async (filePath: string): Promise<void> => {
  const { error } = await supabase
    .rpc('delete_document', { _file_path: filePath });

  if (error) {
    console.error('Delete document metadata error:', error.message);
    throw new Error(error.message);
  }
};
