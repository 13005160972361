import { MenuData } from "../types/menu";
import { PATHS } from "../paths";

export const getMenu = async (role: string): Promise<MenuData> => {
  if (role === "admin") {
    return {
      data: [
        {
          group_id: "1",
          group_name: "コール",
          menu: [
            {
              number: 1,
              title: "オーナー管理",
              url: PATHS.CUSTOMER,
              icon: "<GroupsOutlinedIcon />",
              popup: false,
            },
            {
              number: 2,
              title: "設定",
              url: PATHS.SETTING,
              icon: "<SettingsOutlinedIcon />",
              popup: false,
            },
            {
              number: 3,
              title: "ログアウト",
              url: PATHS.AUTH_SIGNIN,
              icon: "<LogoutOutlined />",
              popup: false,
            },
            // {
            //   number: 1,
            //   title: "ドラフト管理",
            //   url: PATHS.DRAFT,
            //   icon: "<ReceiptOutlinedIcon />",
            //   popup: false,
            // },
            // {
            //   number: 2,
            //   title: "プロンプト編集",
            //   url: PATHS.PRONPT,
            //   icon: "<ConfirmationNumberOutlined />",
            //   popup: false,
            // },
            // {
            //   number: 3,
            //   title: "アカウント管理",
            //   url: PATHS.ACCOUNT,
            //   icon: "<BusinessOutlined />",
            //   popup: false,
            // },
            // {
            //   number: 4,
            //   title: "設定",
            //   url: PATHS.SETTING,
            //   icon: "<SettingsOutlinedIcon />",
            //   popup: false,
            // },
            // {
            //   number: 5,
            //   title: "パスワード変更",
            //   url: PATHS.AUTH_PASSWORD_RESET,
            //   icon: "<ManageAccountOutlinedIcon />",
            //   popup: false,
            // },
            // {
            //   number: 6,
            //   title: "ログアウト",
            //   url: PATHS.AUTH_LOGIN,
            //   icon: "<LogoutOutlined />",
            //   popup: false,
            // },
          ],
        },
      ],
    };
  } else {
    return {
      data: [
        {
          group_id: "1",
          group_name: "コール",
          menu: [
            {
              number: 1,
              title: "オーナー管理",
              url: PATHS.CUSTOMER,
              icon: "<GroupsOutlinedIcon />",
              popup: false,
            },
            {
              number: 2,
              title: "設定",
              url: PATHS.SETTING,
              icon: "<SettingsOutlinedIcon />",
              popup: false,
            },
            {
              number: 3,
              title: "ログアウト",
              url: PATHS.AUTH_SIGNIN,
              icon: "<LogoutOutlined />",
              popup: false,
            },
            // {
            //   number: 1,
            //   title: "ドラフト管理",
            //   url: PATHS.DRAFT,
            //   icon: "<ReceiptOutlinedIcon />",
            //   popup: false,
            // },
            // {
            //   number: 4,
            //   title: "設定",
            //   url: PATHS.SETTING,
            //   icon: "<SettingsOutlinedIcon />",
            //   popup: false,
            // },
            // {
            //   number: 5,
            //   title: "パスワード変更",
            //   url: PATHS.AUTH_PASSWORD_RESET,
            //   icon: "<ManageAccountOutlinedIcon />",
            //   popup: false,
            // },
            // {
            //   number: 6,
            //   title: "ログアウト",
            //   url: PATHS.AUTH_LOGIN,
            //   icon: "<LogoutOutlined />",
            //   popup: false,
            // },
          ],
        },
      ],
    };
  }
};
