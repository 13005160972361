import { FC, ReactNode } from "react";
import { Grid } from "@mui/material";
import AuthHeader from "./authHeader";

interface AuthContainerProps {
  children: ReactNode;
}

const AuthContainer: FC<AuthContainerProps> = (props) => {
  const { children } = props;
  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <AuthHeader></AuthHeader>
        </Grid>
        <Grid item xs>
          {children}
        </Grid>
      </Grid>
    </>
  );
};
export default AuthContainer;
