import React, { ChangeEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    Box,
    Button,
    Card,
    TextField,
    Typography,
    Alert,
    CircularProgress,
} from "@mui/material";
// import { sendPasswordResetEmail } from "../api/auth";
import AuthContainer from "../../components/authContainer";


const AuthPasswordForget: React.FC = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [isError, setIsError] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
    };

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        setIsLoading(true);
        try {
            //   await sendPasswordResetEmail(email);
            setIsSuccess(true);
            setIsError(false);
        } catch (error) {
            setIsError(true);
            setIsSuccess(false);
        } finally {
            setIsLoading(false);
        }
    };

    // ログイン画面へ遷移する関数
    const navigateToLogin = () => {
        navigate("/signin");
    };

    return (
        <AuthContainer>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    alignContent: "center",
                    height: "85vh",
                }}
            >
                <Card
                    elevation={3}
                    sx={{ margin: "auto", display: "flex", flexDirection: "column" }}
                >
                    <Box
                        width={"540px"}
                        paddingInline={2}
                        paddingBlock={4}
                        display={"flex"}
                        flexDirection={"column"}
                        gap={2}
                    >
                        <Typography variant="h5" paddingBlock={2}>
                            パスワードリセット
                        </Typography>
                        <Typography variant="body1">
                            メールアドレスを入力してください。{' '}
                            <br />
                            パスワードリセットのメールを送信します。
                        </Typography>
                        {isError && (
                            <Alert severity="error">
                                エラーが発生しました。もう一度お試しください。
                            </Alert>
                        )}
                        {isSuccess && (
                            <Alert severity="success">
                                パスワードリセットのメールを送信しました。メールをご確認ください。
                            </Alert>
                        )}
                        <form onSubmit={handleSubmit}>
                            <TextField
                                fullWidth
                                value={email}
                                onChange={handleEmailChange}
                                label="メールアドレス"
                                type="email"
                                sx={{ mb: 2 }}
                            />
                            <Button
                                type="submit"
                                variant="contained"
                                fullWidth
                                sx={{ height: "42px" }}
                            >
                                {isLoading ? <CircularProgress size={24} /> : "メールを送信"}
                            </Button>
                            <Button
                                onClick={navigateToLogin}
                                sx={{ mt: 2 }}
                                variant="text"
                                fullWidth
                            >
                                ログイン画面に戻る
                            </Button>
                        </form>
                    </Box>
                </Card>
            </Box>
        </AuthContainer>
    );
};

export default AuthPasswordForget;
