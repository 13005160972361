import { removeFile } from "../service/storageService";
import { supabase } from "../utils/supabase";
import { fetchCustomerDocuments } from "./syorui";

//特定の顧客取得
export const getCustomer = async (customer_id: number) => {
  try {
    const { data, error } = await supabase
      .rpc('get_customer_info', { customer_id });
    if (error) {
      console.error('Error fetching customer info:', error.message);
      // エラーオブジェクトを含むレスポンスを返す
      return { data: null, error: error.message };
    }
    return { data, error: null };
  } catch (err) {
    console.error('Unexpected error:', err);
    // 例外を含むレスポンスを返す
    return { data: null, error: 'Unexpected error occurred' };
  }
};

//ポイント履歴の追加
export async function insertPointHistoryAndUpdateCustomer(customerId: number, content: string, changePoint: number, changedAt: string) {
  const { error, data } = await supabase
    .rpc('insert_point_history_and_update_customer', {
      _customer_table_id: customerId,
      _content: content,
      _change_point: changePoint,
      _changed_at: changedAt
    });
  if (error) {
    throw new Error(error.message);
  }
  return data;
}

//ポイント履歴の削除
export const deletePointHistoryAndUpdateCustomer = async (pointHistoryId: number) => {
  const { error, data } = await supabase
    .rpc('delete_point_history_and_update_customer', {
      _point_history_id: pointHistoryId
    });
  if (error) {
    throw new Error(error.message);
  }
  return data;
};

//顧客基本情報
export const updateCustomerInfo = async (customerData: { customer_id: any; name: any; name_kana: any; nick_name: any; post_number: any; zyusyo: any; kotei_tell_number: any; tell_number: any; kubun: any; tantosya: any; history: any; setting_state: any }) => {
  const { data, error } = await supabase
    .rpc('update_customer_info', {
      _customer_id: customerData.customer_id,
      _name: customerData.name,
      _name_kana: customerData.name_kana,
      _nick_name: customerData.nick_name,
      _post_number: customerData.post_number,
      _zyusyo: customerData.zyusyo,
      _kotei_tell_number: customerData.kotei_tell_number,
      _tell_number: customerData.tell_number,
      _kubun: customerData.kubun,
      _tantosya: customerData.tantosya,
      _history: customerData.history,
      _setting_state: customerData.setting_state
    });
  if (error) {
    console.error('Error updating customer info:', error.message);
    throw new Error('更新に失敗しました。もう一度お試しください。');
  }
  console.log('Customer info updated successfully', data);
};

export const deleteCustomer = async (_customer_id: number) => {
  try {
    // 顧客に関連するドキュメントの取得
    const documentsData = await fetchCustomerDocuments(_customer_id);
    if (!documentsData) throw new Error(`Failed to fetch documents for customer ${_customer_id}`);

    //先にDBから情報を削除
    await supabase.rpc('delete_customer', { _customer_id: _customer_id });

    // 各ドキュメントの削除
    for (const doc of documentsData) {
      try {
        await removeFile('syorui', doc.file_path);
      } catch (removeError) {
        console.error(`Failed to remove file for customer ${_customer_id}: ${removeError}`);
        // エラーが発生しても処理を継続
      }
    }
  } catch (error) {
    console.error('Error during customer deletion process:', error);
    // 必要に応じて、ここでクリーンアップやさらなるエラー処理を行う
  }
};
