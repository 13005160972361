import { Box, Card, Step, StepLabel, Stepper } from "@mui/material";
import { FC } from "react";

export type DraftDetailStepperProps = {
  activeStep: number;
};

const DraftDetailStepper: FC<DraftDetailStepperProps> = (props) => {
  const steps = ["PR作成", "内容修正", "保存"];

  return (
    <Card
      sx={{
        width: "100%",
        padding: 1,
        rowGap: 2,
        display: "flex",
        flexDirection: "column",
        paddingBlock: 5,
      }}
    >
      <Box width="100%">
        <Stepper activeStep={props.activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
    </Card>
  );
};

export default DraftDetailStepper;
