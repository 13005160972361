import LinkOutlinedIcon from "@mui/icons-material/LinkOutlined";
import LoadingButton from "@mui/lab/LoadingButton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { FC, useEffect, useState } from "react";

import { HelpOutlineOutlined } from "@mui/icons-material";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Card,
  DialogContentText,
  IconButton,
  LinearProgress,
  Step,
  StepLabel,
  Stepper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { createPRText } from "../../api/sakuzyoyotei/pr";
import { PRDetail, PRGenerate, PRRequest } from "../../types/sakuzyoyotei/pr";
import { useLocation } from "react-router-dom";
import { getCompany } from "../../api/sakuzyoyotei/company";
import { Company } from "../../types/sakuzyoyotei/company";
import { InputMode } from "../../types/inputMode";

interface AlertDialogProps {
  title: String;
  content: String;
  sample: String;
}

function AlertDialog(props: AlertDialogProps): JSX.Element {
  const { title, content, } = props;

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <IconButton onClick={handleClickOpen}>
        <HelpOutlineOutlined />
      </IconButton>
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent sx={{ display: "grid", rowGap: 2 }}>
          <Alert severity="info">
            <AlertTitle>ポイント</AlertTitle>
            <Typography variant="body2" sx={{ whiteSpace: "pre-wrap" }}>
              {content}
            </Typography>
          </Alert>
          {/* <DialogContentText>
            <Typography variant="h6">入力例</Typography>
          </DialogContentText>
          <DialogContentText sx={{ whiteSpace: "pre-wrap" }}>
            {sample}
          </DialogContentText> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>閉じる</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export type PlaceHolder = {
  fitstTitle: string;
  fitstContent: string;
  secondTitle: string;
  secondContent: string;
};

export type DraftDetailCreateProps = {
  data: PRDetail | undefined;
  generate: PRGenerate | null;
  setGenerate: (generate: PRGenerate, request: PRRequest) => void;
  handleTabChange: (tabId: string) => void;
};

const DraftDetailCreate: FC<DraftDetailCreateProps> = (props) => {
  const steps = ["PR作成", "内容修正", "保存"];

  // 変数
  const [pr_target, setPrTarget] = useState("");
  const [pr_content, setPrContent] = useState("");
  const [company_summary, setCompanySummary] = useState("");
  const [press_url_1, setPressUrl1] = useState("");
  const [press_url_2, setPressUrl2] = useState("");
  const [press_url_3, setPressUrl3] = useState("");

  // エラー
  const [prTargetError, setPrTargetError] = useState(false);
  const [prContentError, setPrContentError] = useState(false);
  const [error_press_url_1, setErrorPressUrl1] = useState(false);
  const [error_press_url_2, setErrorPressUrl2] = useState(false);
  const [error_press_url_3, setErrorPressUrl3] = useState(false);

  // 画面制御
  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState(0);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const categoryParam = queryParams.get("category");
  const [placeholder, setPlaceholder] = useState<PlaceHolder>(
    {} as PlaceHolder
  );
  const mode = queryParams.get("mode") as InputMode;
  const [processingDialogOpen, setProcessingDialogOpen] = useState(false);

  useEffect(() => {
    const request = props.data?.data.request;

    setPrTarget(request?.pr_target ?? "");
    setPrContent(request?.pr_content ?? "");
    setCompanySummary(request?.company_summary ?? "");
    setPressUrl1(request?.press_url_1 ?? "");
    setPressUrl2(request?.press_url_2 ?? "");
    setPressUrl3(request?.press_url_3 ?? "");

    if (mode === InputMode.CREATE) {
      getCompany().then((res: Company) => {
        setCompanySummary(res.data.summary);
      });
    }

    // パラメータがある場合とない場合で分岐する
    if (categoryParam) {
      setCategory(parseInt(categoryParam));
    } else {
      request?.category && setCategory(request.category);
    }

    switch (category) {
      case 1:
        setPlaceholder({
          fitstTitle: "発表する商品・サービス名",
          fitstContent:
            "〇〇を販売開始 や 〇〇を期間限定販売など、商品やサービスの発表内容を簡潔に記載してください。",
          secondTitle: "プレスリリースに記載したい内容",
          secondContent:
            "・〇〇の販売を開始\n・販売開始はmm月dd日から\n・期間中はキャンペーンも開催・キャンペーンは〇〇な内容\n・〇〇という背景を受け商品を企画\n・〇〇は△△で発売予定・合わせてmm月dd日からCM放送を開始\n・CMの内容は✕✕で、■■な仕上がりに",
        });
        break;
      case 2:
        setPlaceholder({
          fitstTitle: "イベントに関する基本情報",
          fitstContent:
            "〇〇を開催 や 〇〇に出店などイベントに関する内容を簡潔に記載してください。",
          secondTitle: "リリースに含める詳細情報",
          secondContent:
            "・イベントはmm月dd日から開始\n・イベント中はSNSキャンペーンも同時開催\n・出店の目的は■■・出店することで〇〇な効果を見込む\n・イベントは〇〇な理由で盛況\n・注意事項は△△",
        });
        break;
      case 3:
        setPlaceholder({
          fitstTitle: "キャンペーンの主題",
          fitstContent: "キャンペーン名は正式名称で記入してください",
          secondTitle: "プレスリリースに含めたいキャンペーン情報",
          secondContent:
            "・〇〇を記念してキャンペーンを開催\n・〇〇への感謝キャンペーンを実施\n・キャンペーンへの参加方法\n・経路\n・注意事項は△△発表するキャンペーンに関する情報を箇条書きで記載してください。",
        });
        break;
      case 4:
        setPlaceholder({
          fitstTitle: "調査概要",
          fitstContent: "調査の概要を記入してください",
          secondTitle: "調査結果",
          secondContent:
            "・〇〇は前年と比較し1.0pt増加した\n・△△を意識するユーザーは50%にもぼる\n・■■の結果は60%増加という結果に調査結果にあたる数値や比較内容を箇条書きで記載してください。",
        });

        break;
      case 5:
        setPlaceholder({
          fitstTitle: "業務提携に関する情報",
          fitstContent: "発表内容を簡潔に記入してください",
          secondTitle: "プレスリリースに含めたい詳細情報",
          secondContent:
            "業務提携の目的や今後の展望など発表したい内容を詳細に記入してください",
        });
        break;
      case 6:
        setPlaceholder({
          fitstTitle: "人事発表に関する情報",
          fitstContent: "発表内容を簡潔に記入してください",
          secondTitle: "プレスリリースに含めたい詳細情報",
          secondContent:
            "経歴や今後の展望、コメントなどリリースに含めたい内容を詳細に記入してください",
        });
        break;
      default:
        setPlaceholder({
          fitstTitle: "発表対象",
          fitstContent: "発表対象を記入してください",
          secondTitle: "発表内容",
          secondContent: "発表内容を記入してください",
        });
        break;
    }

    return () => { };
  }, [mode, category, categoryParam, props.data]);

  const validateUrl = (url: string) => {
    if (url === "") return true;

    return url.includes("https://digitalpr.jp/");
  };

  return (
    <Box
      flexGrow={1}
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"flex-start"}
      alignItems={"flex-start"}
      rowGap={2}
      padding={2}
    >
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        width={"100%"}
      // mb={3}
      >
        <Typography variant="h5">プレスリリース新規作成</Typography>
        <LoadingButton
          loading={loading}
          variant="contained"
          onClick={async () => {
            const urlError1 = !validateUrl(press_url_1);
            const urlError2 = !validateUrl(press_url_2);
            const urlError3 = !validateUrl(press_url_3);
            const prTargetError = !pr_target.trim();
            const prContentError = !pr_content.trim();

            setErrorPressUrl1(urlError1);
            setErrorPressUrl2(urlError2);
            setErrorPressUrl3(urlError3);
            setPrTargetError(prTargetError);
            setPrContentError(prContentError);

            if (
              prTargetError ||
              prContentError ||
              urlError1 ||
              urlError2 ||
              urlError3
            ) {
              setLoading(false);
              setProcessingDialogOpen(false); // エラーがあればダイアログを閉じる

              return;
            }

            setLoading(true);
            setProcessingDialogOpen(true); // ダイアログを表示する

            const req = {
              pr_target: pr_target,
              pr_content: pr_content,
              company_summary: company_summary,
              press_url_1: press_url_1,
              press_url_2: press_url_2,
              press_url_3: press_url_3,
              category: category,
            } as PRRequest;

            try {
              const res = await createPRText(req);
              props.setGenerate(res, req);
            } catch (error) {
              // エラー処理
              console.error("An error occurred:", error);
            } finally {
              setLoading(false);
              setProcessingDialogOpen(false); // 成功でも失敗でも処理が完了したらダイアログを閉じる
            }

            props.handleTabChange("first");
          }}
        >
          生成開始
        </LoadingButton>
      </Box>
      <Dialog
        open={processingDialogOpen}
        // onClose={() => setProcessingDialogOpen(false)}
        disableEscapeKeyDown
      >
        <DialogTitle id="processing-dialog-title">処理中</DialogTitle>
        <DialogContent>
          <DialogContentText id="processing-dialog-description">
            1分ほどかかります。画面を閉じないでください。
          </DialogContentText>
          <LinearProgress sx={{ marginTop: "16px" }} />
        </DialogContent>
      </Dialog>
      <Box
        display={
          props.generate?.daily_limit && props.generate.daily_limit < 10
            ? "block"
            : "none"
        }
      >
        <Alert severity="error" sx={{ width: "100%" }}>
          今月のプロンプトの再生成可能件数が、残り10回以下となっています。
        </Alert>
      </Box>
      <Card
        sx={{
          width: "100%",
          padding: 1,
          rowGap: 2,
          display: "flex",
          flexDirection: "column",
          paddingBlock: 5,
        }}
      >
        <Box width="100%">
          <Stepper activeStep={0} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
      </Card>
      {/* 発表対象ブロック Start */}
      <Box
        width={"100%"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Box>
          <Typography variant="h6">{placeholder.fitstTitle}</Typography>
          <Typography variant="body2">
            {placeholder.fitstTitle}を入力してください
          </Typography>
        </Box>
        <AlertDialog
          title={placeholder.fitstTitle}
          content={placeholder.fitstContent}
          sample=""
        />
      </Box>
      <TextField
        value={pr_target}
        onChange={(event) => setPrTarget(event.target.value)}
        fullWidth
        multiline
        minRows={15}
        placeholder={placeholder.fitstContent}
        error={prTargetError}
        helperText={prTargetError ? "必須項目です" : ""}
      ></TextField>
      {/* 発表対象ブロック End */}
      {/* 発表する内容ブロック Start */}
      <Box
        width={"100%"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Box>
          <Typography variant="h6">{placeholder.secondTitle}</Typography>
          <Typography variant="body2">
            {placeholder.secondTitle}を入力してください
          </Typography>
        </Box>
        <AlertDialog
          title={placeholder.secondTitle}
          content={placeholder.secondContent}
          sample=""
        />
        {/* <AlertDialog /> */}
      </Box>
      <TextField
        value={pr_content}
        onChange={(event) => setPrContent(event.target.value)}
        fullWidth
        multiline
        minRows={15}
        placeholder={placeholder.secondContent}
        error={prContentError}
        helperText={prContentError ? "必須項目です" : ""}
      ></TextField>
      {/* 発表する内容ブロック End */}
      {/* 発表する内容ブロック Start */}
      <Box
        width={"100%"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Box>
          <Typography variant="h6">会社概要</Typography>
          <Typography variant="body2">会社の概要を入力してください</Typography>
        </Box>
        <AlertDialog
          title="会社概要"
          content={"- 会社名\n- 所在地\n- 代表者名"}
          sample=""
        />
      </Box>
      <TextField
        value={company_summary}
        onChange={(event) => setCompanySummary(event.target.value)}
        fullWidth
        multiline
        minRows={15}
        placeholder={"- 会社名\n- 所在地\n- 代表者名"}
      ></TextField>
      {/* 発表する内容ブロック End */}
      <Box>
        <Box display={"flex"} alignItems={"center"}>
          <Typography variant="h6">参照URL</Typography>
          <IconButton
            onClick={() => window.open("https://digitalpr.jp/", "_blank")}
          >
            <LinkOutlinedIcon />
          </IconButton>
        </Box>

        <Typography variant="body2" whiteSpace={"pre-wrap"}>
          {
            "Digital PR Platformの自社プレスリリースURLを入力してください。参照してドラフト生成を行います。\n参照URLを入力した生成にはChatGPTのアウトプットにお時間がかかることがございます。"
          }
        </Typography>
      </Box>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>参照URL1</TableCell>
            <TableCell>
              <TextField
                value={press_url_1}
                onChange={(event) => setPressUrl1(event.target.value)}
                fullWidth
                helperText="https://digitalpr.jp/で始まるURLを入力してください"
                error={error_press_url_1}
              ></TextField>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>参照URL2</TableCell>
            <TableCell>
              <TextField
                value={press_url_2}
                onChange={(event) => setPressUrl2(event.target.value)}
                fullWidth
                helperText="https://digitalpr.jp/で始まるURLを入力してください"
                error={error_press_url_2}
              ></TextField>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>参照URL3</TableCell>
            <TableCell>
              <TextField
                value={press_url_3}
                onChange={(event) => setPressUrl3(event.target.value)}
                fullWidth
                helperText="https://digitalpr.jp/で始まるURLを入力してください"
                error={error_press_url_3}
              ></TextField>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  );
};
export default DraftDetailCreate;
