import React, { FC, ReactNode } from "react";
import { Box } from "@mui/material";
import SideBar from "./sideBar";
import Header from "./header";

interface PageContainerProps {
  children: ReactNode;
  isWide?: boolean;
  onlyContent?: boolean;
}

const PageContainer: FC<PageContainerProps> = (props) => {
  const { children, isWide = false, onlyContent = true } = props;

  return (
    <Box
      display={"flex"}
      alignItems={"stretch"}
      overflow={"scroll"}
      width={"100vw"}
    >
      {onlyContent && <SideBar />}
      <Box
        display={"flex"}
        flexDirection={"column"}
        flexGrow={1}
        maxHeight={"100vh"}
        height={"100vh"}
        minWidth={"960px"}
        sx={{
          // backgroundColor: "#FAFAFA",
          overflowY: "auto",
        }}
      >
        {onlyContent && <Header />}
        <Box
          maxWidth={isWide ? "none" : "1600px"}
          width={"100%"}
          marginInline={"auto"}
          paddingInline={3}
          paddingBlock={5}
          display={"grid"}
          gap={2}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export default PageContainer;
