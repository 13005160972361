import axios, { AxiosResponse } from "axios";
import { getAxiosConfig,  } from "./config";
import { Company, CompanyPage, CompanyUpdate } from "../../types/sakuzyoyotei/company";

// getDetail
export const getCompany = async (): Promise<Company> => {
  const response = await axios.get(
    `${process.env.REACT_APP_API_URL}/company/get`,
    getAxiosConfig()
  );

  return response.data;
};

export const getCompanyList = async (pageNum: number): Promise<CompanyPage> => {
  const response = await axios.get(
    `${process.env.REACT_APP_API_URL}/admin/get/companies/${pageNum}`,
    getAxiosConfig()
  );

  return response.data;
};

export const deleteCompany = async (
  companyID: string
): Promise<AxiosResponse> => {
  console.log(companyID);

  return await axios.post(
    `${process.env.REACT_APP_API_URL}/admin/company/delete/${companyID}`,
    {},
    getAxiosConfig()
  );
};

// updateCompany
export const updateCompany = async (
  update: CompanyUpdate
): Promise<AxiosResponse> => {
  return await axios.post(
    `${process.env.REACT_APP_API_URL}/company/update`,
    {
      company_id: update.company_id,
      company_name: update.company_name,
      address: update.address,
      tel: update.tel,
      staff: update.staff,
      email: update.email,
      summary: update.summary,
      role: update.role,
      status: "active",
      create_datetime: update.create_datetime,
      update_datetime: update.update_datetime,
    },
    getAxiosConfig()
  );
};
