export const PATHS = {
  CUSTOMER: "/customer",
  SETTING: "/setting",
  // ユーザー
  AUTH_SIGNIN: "/signin",
  AUTH_SIGNUP: "/signup",
  AUTH_PASSWORD_RESET: "/passwordReset",
  AUTH_PASSWORD_FORGET: "/passwordForget",
  AUTH_ACCOUNT: "/account",
  DRAFT: "/draft",
  // 管理者
  PRONPT: "/prompt",
  ACCOUNT: "/company",
} as const;

