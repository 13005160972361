const NotFoundPage = () => {
  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh', // ビューポートの高さを100%に設定
      flexDirection: 'column', // 子要素を縦方向に並べる
    }}>
      <h1>404 Not Found</h1>
      <p>お探しのページは見つかりませんでした。</p>
    </div>
  );
}

export default NotFoundPage;
