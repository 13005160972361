import {
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";

import { FC } from "react";

// プロパティの型定義
interface SubMenuItemProps {
  text: string;
  selected: boolean;
  onClick: () => void;
}

// ListItemを独立したコンポーネントとして定義
const SubMenuItem: FC<SubMenuItemProps> = ({
  text,
  selected,
  onClick,
}) => (
  <ListItem disablePadding>
    <ListItemButton
      onClick={onClick}
      sx={{
        borderRadius: "4px",
        width: "100%",
        "&:hover": {
          backgroundColor: "#5B98F414",
        },
        ...(selected && {
          backgroundColor: "#5B98F414",
        }),
      }}
    >
      <ListItemText>
        <Typography whiteSpace={"nowrap"}>{text}</Typography>
      </ListItemText>
    </ListItemButton>
  </ListItem>
);

export default SubMenuItem;
